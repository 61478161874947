import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTecAggregationConfigGetTecAggregationConfigs = gql`
    query EditTecAggregationConfigGetTecAggregationConfigs($shopId: uuid!) {
  tecAggregationConfig(where: {shopId: {_eq: $shopId}}) {
    id
    enabled
    companyCode
    shopCode
    posNumber
    ftpHost
    ftpPassword
    ftpPort
    ftpUser
    integrationType
    salesDataPutPath
  }
}
    `;
export const EditTecAggregationConfigUpsertTecAggregationConfig = gql`
    mutation EditTecAggregationConfigUpsertTecAggregationConfig($tecAggregationConfig: tecAggregationConfig_insert_input!) {
  insert_tecAggregationConfig_one(
    object: $tecAggregationConfig
    on_conflict: {constraint: tecAggregationConfig_pkey, update_columns: [enabled, companyCode, shopCode, posNumber, ftpHost, ftpPassword, ftpPort, ftpUser, salesDataPutPath, integrationType]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationConfigGetTecAggregationConfigsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditTecAggregationConfigGetTecAggregationConfigsQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationConfig: Array<(
    { __typename?: 'tecAggregationConfig' }
    & Pick<Types.TecAggregationConfig, 'id' | 'enabled' | 'companyCode' | 'shopCode' | 'posNumber' | 'ftpHost' | 'ftpPassword' | 'ftpPort' | 'ftpUser' | 'integrationType' | 'salesDataPutPath'>
  )> }
);

export type EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables = Types.Exact<{
  tecAggregationConfig: Types.TecAggregationConfigInsertInput;
}>;


export type EditTecAggregationConfigUpsertTecAggregationConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tecAggregationConfig_one?: Types.Maybe<(
    { __typename?: 'tecAggregationConfig' }
    & Pick<Types.TecAggregationConfig, 'id'>
  )> }
);


export const EditTecAggregationConfigGetTecAggregationConfigsDocument = gql`
    query EditTecAggregationConfigGetTecAggregationConfigs($shopId: uuid!) {
  tecAggregationConfig(where: {shopId: {_eq: $shopId}}) {
    id
    enabled
    companyCode
    shopCode
    posNumber
    ftpHost
    ftpPassword
    ftpPort
    ftpUser
    integrationType
    salesDataPutPath
  }
}
    `;

/**
 * __useEditTecAggregationConfigGetTecAggregationConfigsQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationConfigGetTecAggregationConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationConfigGetTecAggregationConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationConfigGetTecAggregationConfigsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditTecAggregationConfigGetTecAggregationConfigsQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationConfigGetTecAggregationConfigsQuery, EditTecAggregationConfigGetTecAggregationConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationConfigGetTecAggregationConfigsQuery, EditTecAggregationConfigGetTecAggregationConfigsQueryVariables>(EditTecAggregationConfigGetTecAggregationConfigsDocument, options);
      }
export function useEditTecAggregationConfigGetTecAggregationConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationConfigGetTecAggregationConfigsQuery, EditTecAggregationConfigGetTecAggregationConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationConfigGetTecAggregationConfigsQuery, EditTecAggregationConfigGetTecAggregationConfigsQueryVariables>(EditTecAggregationConfigGetTecAggregationConfigsDocument, options);
        }
export type EditTecAggregationConfigGetTecAggregationConfigsQueryHookResult = ReturnType<typeof useEditTecAggregationConfigGetTecAggregationConfigsQuery>;
export type EditTecAggregationConfigGetTecAggregationConfigsLazyQueryHookResult = ReturnType<typeof useEditTecAggregationConfigGetTecAggregationConfigsLazyQuery>;
export type EditTecAggregationConfigGetTecAggregationConfigsQueryResult = Apollo.QueryResult<EditTecAggregationConfigGetTecAggregationConfigsQuery, EditTecAggregationConfigGetTecAggregationConfigsQueryVariables>;
export const EditTecAggregationConfigUpsertTecAggregationConfigDocument = gql`
    mutation EditTecAggregationConfigUpsertTecAggregationConfig($tecAggregationConfig: tecAggregationConfig_insert_input!) {
  insert_tecAggregationConfig_one(
    object: $tecAggregationConfig
    on_conflict: {constraint: tecAggregationConfig_pkey, update_columns: [enabled, companyCode, shopCode, posNumber, ftpHost, ftpPassword, ftpPort, ftpUser, salesDataPutPath, integrationType]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationFn = Apollo.MutationFunction<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>;

/**
 * __useEditTecAggregationConfigUpsertTecAggregationConfigMutation__
 *
 * To run a mutation, you first call `useEditTecAggregationConfigUpsertTecAggregationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationConfigUpsertTecAggregationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTecAggregationConfigUpsertTecAggregationConfigMutation, { data, loading, error }] = useEditTecAggregationConfigUpsertTecAggregationConfigMutation({
 *   variables: {
 *      tecAggregationConfig: // value for 'tecAggregationConfig'
 *   },
 * });
 */
export function useEditTecAggregationConfigUpsertTecAggregationConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>(EditTecAggregationConfigUpsertTecAggregationConfigDocument, options);
      }
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationHookResult = ReturnType<typeof useEditTecAggregationConfigUpsertTecAggregationConfigMutation>;
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationResult = Apollo.MutationResult<EditTecAggregationConfigUpsertTecAggregationConfigMutation>;
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationOptions = Apollo.BaseMutationOptions<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>;