import * as Types from '../../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MessageDeliveriesRefetchTargetCount = gql`
    mutation MessageDeliveriesRefetchTargetCount($input: CountMessageDeliveryTargetsInput!) {
  dashboardAccountCountMessageDeliveryTargets(input: $input) {
    targetCount
  }
}
    `;
export type MessageDeliveriesRefetchTargetCountMutationVariables = Types.Exact<{
  input: Types.CountMessageDeliveryTargetsInput;
}>;


export type MessageDeliveriesRefetchTargetCountMutation = (
  { __typename?: 'mutation_root' }
  & { dashboardAccountCountMessageDeliveryTargets: (
    { __typename?: 'UpdateTargetCountOutput' }
    & Pick<Types.UpdateTargetCountOutput, 'targetCount'>
  ) }
);


export const MessageDeliveriesRefetchTargetCountDocument = gql`
    mutation MessageDeliveriesRefetchTargetCount($input: CountMessageDeliveryTargetsInput!) {
  dashboardAccountCountMessageDeliveryTargets(input: $input) {
    targetCount
  }
}
    `;
export type MessageDeliveriesRefetchTargetCountMutationFn = Apollo.MutationFunction<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>;

/**
 * __useMessageDeliveriesRefetchTargetCountMutation__
 *
 * To run a mutation, you first call `useMessageDeliveriesRefetchTargetCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveriesRefetchTargetCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageDeliveriesRefetchTargetCountMutation, { data, loading, error }] = useMessageDeliveriesRefetchTargetCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageDeliveriesRefetchTargetCountMutation(baseOptions?: Apollo.MutationHookOptions<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>(MessageDeliveriesRefetchTargetCountDocument, options);
      }
export type MessageDeliveriesRefetchTargetCountMutationHookResult = ReturnType<typeof useMessageDeliveriesRefetchTargetCountMutation>;
export type MessageDeliveriesRefetchTargetCountMutationResult = Apollo.MutationResult<MessageDeliveriesRefetchTargetCountMutation>;
export type MessageDeliveriesRefetchTargetCountMutationOptions = Apollo.BaseMutationOptions<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>;