import React, { memo } from "react";
import { Input } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

import { useEditTecAggregationConfigFormPermission } from "../useEditTecAggregationConfigFormPermission";

type Props = Omit<FormItemProps, "children">;

export const SalesDataPutPathField = memo<Props>((props) => {
  const { canEditForm } = useEditTecAggregationConfigFormPermission();
  return (
    <FormItem label="PUT先パス" name="salesDataPutPath" {...props}>
      <Input disabled={!canEditForm} />
    </FormItem>
  );
});
