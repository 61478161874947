import React, { memo } from "react";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { useEditTecAggregationConfigFormPermission } from "../useEditTecAggregationConfigFormPermission";

type Props = Omit<FormItemProps, "children">;

export const FtpPortField = memo<Props>((props) => {
  const { canEditForm } = useEditTecAggregationConfigFormPermission();
  return (
    <FormItem
      label="FTPポート"
      name="ftpPort"
      rules={[{ required: canEditForm }]}
      required
      {...props}
    >
      <InputNumber fullWidth disabled={!canEditForm} />
    </FormItem>
  );
});
