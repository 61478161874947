import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { integrationTypes } from "models/tecAggregationIntegrationType";

import { TecAggregationIntegrationTypeEnum } from "types/graphql";

type Props = { integrationType: TecAggregationIntegrationTypeEnum };

export const IntegrationTypeField = memo<Props>(({ integrationType }) => {
  const selectedKeys = useMemo(() => (integrationType ? [integrationType] : []), [integrationType]);

  return (
    <Menu selectedKeys={selectedKeys} mode="horizontal">
      {Object.entries(integrationTypes).map(([key, value]) => (
        <Menu.Item key={key}>
          <Link to={`/tec/config/${key}`} replace>
            {value}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
});
