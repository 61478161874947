import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenusGetMenus = gql`
    query MenusGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}
    order_by: {menuId: asc}
  ) {
    id
    serial
    price
    categoryMenus(
      order_by: [{priority: asc}, {_categoryId: asc}]
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) @asScalar(key: "MenusGetMenus") {
      categoryMenuId
      displayType
      category {
        categoryId
        name
        priority
      }
    }
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
      order_by: {shopId: asc}
    ) @asScalar(key: "MenusGetMenus") {
      id
      shopId
    }
    totalDealingShopCount
    description
    imageUrl
    name
    shopSideName
    openPrice
    receiptDisplayName
    featuredLabelText
    orderMaxNum
    unitMaxOrderNumForNumPeople
    taxMethod
    taxRate
    costPrice
    costTaxRate
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) @asScalar(key: "MenusGetMenus") {
      option {
        name
        optionId
      }
    }
    orderableTime @asScalar(key: "MenusGetMenus") {
      name
    }
    compulsoryAppetizerConfigs @asScalar(key: "MenusGetMenus") {
      id
    }
    menuType
  }
}
    `;
export const MenusGetCategories = gql`
    query MenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, _not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const MenusGetShops = gql`
    query MenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const MenusArchiveMenu = gql`
    mutation MenusArchiveMenu($input: ArchiveMenuInput!) {
  archiveMenu(input: $input) {
    result
  }
}
    `;
export type MenusGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'serial' | 'price' | 'totalDealingShopCount' | 'description' | 'imageUrl' | 'name' | 'shopSideName' | 'openPrice' | 'receiptDisplayName' | 'featuredLabelText' | 'orderMaxNum' | 'unitMaxOrderNumForNumPeople' | 'taxMethod' | 'taxRate' | 'costPrice' | 'costTaxRate' | 'menuType'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId' | 'displayType'>
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name' | 'priority'>
      ) }
    )>, shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & Pick<Types.ShopMenu, 'id' | 'shopId'>
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'name' | 'optionId'>
      ) }
    )>, orderableTime?: Types.Maybe<(
      { __typename?: 'orderableTime' }
      & Pick<Types.OrderableTime, 'name'>
    )>, compulsoryAppetizerConfigs: Array<(
      { __typename?: 'compulsoryAppetizerConfig' }
      & Pick<Types.CompulsoryAppetizerConfig, 'id'>
    )> }
  )> }
);

export type MenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type MenusGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MenusGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type MenusArchiveMenuMutationVariables = Types.Exact<{
  input: Types.ArchiveMenuInput;
}>;


export type MenusArchiveMenuMutation = (
  { __typename?: 'mutation_root' }
  & { archiveMenu: (
    { __typename?: 'ArchiveMenuResult' }
    & Pick<Types.ArchiveMenuResult, 'result'>
  ) }
);


export const MenusGetMenusDocument = gql`
    query MenusGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}
    order_by: {menuId: asc}
  ) {
    id
    serial
    price
    categoryMenus(
      order_by: [{priority: asc}, {_categoryId: asc}]
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) @asScalar(key: "MenusGetMenus") {
      categoryMenuId
      displayType
      category {
        categoryId
        name
        priority
      }
    }
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
      order_by: {shopId: asc}
    ) @asScalar(key: "MenusGetMenus") {
      id
      shopId
    }
    totalDealingShopCount
    description
    imageUrl
    name
    shopSideName
    openPrice
    receiptDisplayName
    featuredLabelText
    orderMaxNum
    unitMaxOrderNumForNumPeople
    taxMethod
    taxRate
    costPrice
    costTaxRate
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) @asScalar(key: "MenusGetMenus") {
      option {
        name
        optionId
      }
    }
    orderableTime @asScalar(key: "MenusGetMenus") {
      name
    }
    compulsoryAppetizerConfigs @asScalar(key: "MenusGetMenus") {
      id
    }
    menuType
  }
}
    `;

/**
 * __useMenusGetMenusQuery__
 *
 * To run a query within a React component, call `useMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenusGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenusGetMenusQuery(baseOptions: Apollo.QueryHookOptions<MenusGetMenusQuery, MenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenusGetMenusQuery, MenusGetMenusQueryVariables>(MenusGetMenusDocument, options);
      }
export function useMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenusGetMenusQuery, MenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenusGetMenusQuery, MenusGetMenusQueryVariables>(MenusGetMenusDocument, options);
        }
export type MenusGetMenusQueryHookResult = ReturnType<typeof useMenusGetMenusQuery>;
export type MenusGetMenusLazyQueryHookResult = ReturnType<typeof useMenusGetMenusLazyQuery>;
export type MenusGetMenusQueryResult = Apollo.QueryResult<MenusGetMenusQuery, MenusGetMenusQueryVariables>;
export const MenusGetCategoriesDocument = gql`
    query MenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, _not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `useMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<MenusGetCategoriesQuery, MenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenusGetCategoriesQuery, MenusGetCategoriesQueryVariables>(MenusGetCategoriesDocument, options);
      }
export function useMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenusGetCategoriesQuery, MenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenusGetCategoriesQuery, MenusGetCategoriesQueryVariables>(MenusGetCategoriesDocument, options);
        }
export type MenusGetCategoriesQueryHookResult = ReturnType<typeof useMenusGetCategoriesQuery>;
export type MenusGetCategoriesLazyQueryHookResult = ReturnType<typeof useMenusGetCategoriesLazyQuery>;
export type MenusGetCategoriesQueryResult = Apollo.QueryResult<MenusGetCategoriesQuery, MenusGetCategoriesQueryVariables>;
export const MenusGetShopsDocument = gql`
    query MenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useMenusGetShopsQuery__
 *
 * To run a query within a React component, call `useMenusGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenusGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenusGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenusGetShopsQuery(baseOptions: Apollo.QueryHookOptions<MenusGetShopsQuery, MenusGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenusGetShopsQuery, MenusGetShopsQueryVariables>(MenusGetShopsDocument, options);
      }
export function useMenusGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenusGetShopsQuery, MenusGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenusGetShopsQuery, MenusGetShopsQueryVariables>(MenusGetShopsDocument, options);
        }
export type MenusGetShopsQueryHookResult = ReturnType<typeof useMenusGetShopsQuery>;
export type MenusGetShopsLazyQueryHookResult = ReturnType<typeof useMenusGetShopsLazyQuery>;
export type MenusGetShopsQueryResult = Apollo.QueryResult<MenusGetShopsQuery, MenusGetShopsQueryVariables>;
export const MenusArchiveMenuDocument = gql`
    mutation MenusArchiveMenu($input: ArchiveMenuInput!) {
  archiveMenu(input: $input) {
    result
  }
}
    `;
export type MenusArchiveMenuMutationFn = Apollo.MutationFunction<MenusArchiveMenuMutation, MenusArchiveMenuMutationVariables>;

/**
 * __useMenusArchiveMenuMutation__
 *
 * To run a mutation, you first call `useMenusArchiveMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenusArchiveMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menusArchiveMenuMutation, { data, loading, error }] = useMenusArchiveMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenusArchiveMenuMutation(baseOptions?: Apollo.MutationHookOptions<MenusArchiveMenuMutation, MenusArchiveMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MenusArchiveMenuMutation, MenusArchiveMenuMutationVariables>(MenusArchiveMenuDocument, options);
      }
export type MenusArchiveMenuMutationHookResult = ReturnType<typeof useMenusArchiveMenuMutation>;
export type MenusArchiveMenuMutationResult = Apollo.MutationResult<MenusArchiveMenuMutation>;
export type MenusArchiveMenuMutationOptions = Apollo.BaseMutationOptions<MenusArchiveMenuMutation, MenusArchiveMenuMutationVariables>;