import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Radio, RadioChangeEvent } from "antd";
import { TableProps } from "antd/lib";
import dayjs from "dayjs";
import { maxBy, minBy, uniq } from "lodash";
import { formatPrice } from "util/formatPrice";

import { Table } from "components/Table";
import { NormalizedSalesAnalyticsRows } from "hooks/useSalesByDayofweekAndHourAnalytics/types";

const TableStyleProvider = styled.div`
  .ant-table-container {
    border: none;
  }
  .ant-table-title {
    background-color: #fafafa;
  }
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    background-color: #fff;
  }
`;

type DataType = {
  key: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
};

const dayofweek = [
  { title: "月", dataIndex: "monday" },
  { title: "火", dataIndex: "tuesday" },
  { title: "水", dataIndex: "wednesday" },
  { title: "木", dataIndex: "thursday" },
  { title: "金", dataIndex: "friday" },
  { title: "土", dataIndex: "saturday" },
  { title: "日", dataIndex: "sunday" },
];

type Props = {
  normalizedRows: NormalizedSalesAnalyticsRows;
  salesValueType: "totalAmount" | "numPeople" | "salesPerCustomer";
  setSalesValueType: React.Dispatch<
    React.SetStateAction<"totalAmount" | "numPeople" | "salesPerCustomer">
  >;
  isLoading: boolean;
};

export const SalesHeatmap = memo<Props>(
  ({ normalizedRows, salesValueType, setSalesValueType, isLoading }) => {
    const maxSalesValue = useMemo(
      () => maxBy(Object.values(normalizedRows).flat(), salesValueType)?.[salesValueType] ?? 0,
      [normalizedRows, salesValueType],
    );
    const minSalesValue = useMemo(
      () => minBy(Object.values(normalizedRows).flat(), salesValueType)?.[salesValueType] ?? 0,
      [normalizedRows, salesValueType],
    );
    const normalizeSales = useCallback(
      (val: number) => (val - minSalesValue) / (maxSalesValue - minSalesValue),
      [maxSalesValue, minSalesValue],
    );

    const hours = uniq(
      Object.entries(normalizedRows)
        .map(([dayofweek, dayofweekRow]) => dayofweekRow.map(({ hour }) => hour))
        .flat(),
    );

    const columns: TableProps<DataType>["columns"] = useMemo(
      () => [
        {
          title: "",
          width: 130,
          dataIndex: "key",
          align: "center",
          fontWeight: "bold",
          render: (text: string) => ({
            props: {
              style: { fontWeight: "bold" },
            },
            children: <div>{text}</div>,
          }),
        },
        ...dayofweek.map((day) => ({
          title:
            day.title === "土" ? (
              <div style={{ color: "#1677FF" }}>{day.title}</div>
            ) : day.title === "日" ? (
              <div style={{ color: "#FF4D4F" }}>{day.title}</div>
            ) : (
              day.title
            ),
          align: "center" as const,
          width: 130,
          dataIndex: day.dataIndex,
          render(text: string) {
            const value =
              salesValueType === "numPeople"
                ? parseInt(text)
                : parseInt(text.replace(/[^\d]/g, ""));
            const defaultStyle = { textAlign: "right" };
            return {
              props: {
                style:
                  text === "-"
                    ? { ...defaultStyle, background: "#fff" }
                    : normalizeSales(value) > 6 / 7
                    ? { ...defaultStyle, background: "#096dd9", color: "#fff", borderColor: "#fff" }
                    : normalizeSales(value) > 5 / 7
                    ? { ...defaultStyle, background: "#1890ff", borderColor: "#fff" }
                    : normalizeSales(value) > 4 / 7
                    ? { ...defaultStyle, background: "#40a9ff", borderColor: "#fff" }
                    : normalizeSales(value) > 3 / 7
                    ? { ...defaultStyle, background: "#69c0ff", borderColor: "#fff" }
                    : normalizeSales(value) > 2 / 7
                    ? { ...defaultStyle, background: "#91d5ff", borderColor: "#fff" }
                    : normalizeSales(value) > 1 / 7
                    ? { ...defaultStyle, background: "#bae7ff", borderColor: "#fff" }
                    : { ...defaultStyle, background: "#e6f7ff" },
              },
              children: <div>{text}</div>,
            };
          },
        })),
      ],
      [normalizeSales, salesValueType],
    );

    const getDataForDayAndHour = useCallback(
      (day: string, hour: string) => {
        const row = normalizedRows[day]?.find((row) => row.hour === hour);
        return salesValueType === "numPeople"
          ? row?.numPeople
            ? String(row.numPeople)
            : "-"
          : row?.[salesValueType]
          ? formatPrice(row[salesValueType])
          : "-";
      },
      [normalizedRows, salesValueType],
    );

    const data = useMemo(
      () =>
        hours.map((hour) => ({
          key: dayjs().hour(parseInt(hour)).format("HH:00"),
          monday: getDataForDayAndHour("月", hour),
          tuesday: getDataForDayAndHour("火", hour),
          wednesday: getDataForDayAndHour("水", hour),
          thursday: getDataForDayAndHour("木", hour),
          friday: getDataForDayAndHour("金", hour),
          saturday: getDataForDayAndHour("土", hour),
          sunday: getDataForDayAndHour("日", hour),
        })),
      [hours, getDataForDayAndHour],
    );

    const onChangeRadio = useCallback(
      (e: RadioChangeEvent) => {
        const value = e.target.value;

        setSalesValueType(value);
      },
      [setSalesValueType],
    );

    return (
      <TableStyleProvider>
        <Table
          columns={columns}
          bordered
          rowKey="id"
          dataSource={data}
          pagination={false}
          loading={isLoading}
          size="small"
          title={() => (
            <Radio.Group value={salesValueType} onChange={onChangeRadio}>
              <Radio value="totalAmount">平均売上</Radio>
              <Radio value="numPeople">平均客数</Radio>
              <Radio value="salesPerCustomer">平均客単価</Radio>
            </Radio.Group>
          )}
          scroll={{ y: "950px" }}
        />
      </TableStyleProvider>
    );
  },
);
