import React, { memo } from "react";
import { Input } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

import { useEditTecAggregationConfigFormPermission } from "../useEditTecAggregationConfigFormPermission";

type Props = Omit<FormItemProps, "children">;

export const FtpUserField = memo<Props>((props) => {
  const { canEditForm } = useEditTecAggregationConfigFormPermission();
  return (
    <FormItem
      label="FTPユーザー名"
      name="ftpUser"
      rules={[{ required: canEditForm }]}
      required
      {...props}
    >
      <Input disabled={!canEditForm} />
    </FormItem>
  );
});
