import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = Omit<FormItemProps, "children">;

export const IsTakeOutField = memo<Props>((props) => (
  <AddMenuFormItem
    valuePropName="checked"
    {...props}
    name="isTakeOut"
    help="テイクアウト、デリバリーなどの店外利用として売上集計します"
  >
    <Checkbox>店外メニューとして利用する</Checkbox>
  </AddMenuFormItem>
));
