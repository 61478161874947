import React, { memo, useState } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import styled from "styled-components";
import { Tooltip } from "antd";
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { useMessageDeliveriesRefetchTargetCountMutation } from "./queries";

const TargetCount = styled.div`
  text-align: right;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  svg {
    fill: #1890ff;
  }
  font-size: 12px;
`;

const StyledSyncOutlined = styled(SyncOutlined)`
  svg {
    fill: #1890ff;
  }
  font-size: 12px;
  cursor: pointer;
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  svg {
    fill: green;
  }
  font-size: 12px;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  svg {
    fill: ${colors.Text.Error};
  }
`;

const IconWrapper = styled.div`
  width: 12px;
  height: 12px;
  display: inline-block;
`;

export const MessageDeliveryTargetCount = memo(
  ({
    messageDeliveryId,
    initialTargetCount,
  }: {
    messageDeliveryId: string;
    initialTargetCount: number | null;
  }) => {
    const [targetCount, setTargetCount] = useState(initialTargetCount);
    const [updatedTargetCount, setUpdatedTargetCount] = useState(false);

    const [refetchTargetCountMutation] = useMessageDeliveriesRefetchTargetCountMutation();

    const [{ loading }, refetchTargetCount] = useAsyncFn(async () => {
      const result = await refetchTargetCountMutation({
        variables: { input: { messageDeliveryId } },
      });

      setTargetCount(result.data?.dashboardAccountCountMessageDeliveryTargets.targetCount ?? null);
      setUpdatedTargetCount(true);
    }, [refetchTargetCountMutation, messageDeliveryId]);

    return (
      <TargetCount>
        {targetCount === null ? <StyledExclamationCircleOutlined /> : targetCount}

        <Spacer size={12} inline />

        <IconWrapper>
          {loading ? (
            <StyledLoadingOutlined />
          ) : updatedTargetCount ? (
            <StyledCheckOutlined />
          ) : (
            <Tooltip title="対象者数を再取得する">
              <StyledSyncOutlined onClick={refetchTargetCount} />
            </Tooltip>
          )}
        </IconWrapper>
      </TargetCount>
    );
  },
);
