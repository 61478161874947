import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditDishUpSlipGroupGetDishUpSlipGroups = gql`
    query EditDishUpSlipGroupGetDishUpSlipGroups($dishUpSlipGroupId: Int!) {
  dishUpSlipGroup(
    where: {serial: {_eq: $dishUpSlipGroupId}, shop: {archivedAt: {_is_null: true}}}
  ) {
    id: serial
    dishUpSlipGroupId
    name
    shopId
    shop {
      companyId
    }
    dishUpSlipGroupRoles {
      roleId
      dishUpSlipGroupId: _dishUpSlipGroupId
    }
  }
}
    `;
export const EditDishUpSlipGroupGetRoles = gql`
    query EditDishUpSlipGroupGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(where: {shop: {archivedAt: {_is_null: true}}}, order_by: {roleId: asc}) {
      id
      name
      roleId
      shop {
        dishUpSlipGroups {
          id: serial
          name
        }
      }
    }
  }
}
    `;
export const EditDishUpSlipGroupUpdateDishUpSlipGroup = gql`
    mutation EditDishUpSlipGroupUpdateDishUpSlipGroup($dishUpSlipGroupId: Int!, $dishUpSlipGroup: dishUpSlipGroup_set_input!, $roleIds: [uuid!]!, $dishUpSlipGroupRoles: [dishUpSlipGroupRoles_insert_input!]!) {
  update_dishUpSlipGroup(
    _set: $dishUpSlipGroup
    where: {serial: {_eq: $dishUpSlipGroupId}}
  ) {
    affected_rows
  }
  delete_dishUpSlipGroupRoles(
    where: {_dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}, roleId: {_nin: $roleIds}}
  ) {
    affected_rows
  }
  insert_dishUpSlipGroupRoles(
    objects: $dishUpSlipGroupRoles
    on_conflict: {constraint: dishUpSlipGroupRoles_roleId_dishUpSlipGroupId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditDishUpSlipGroupGetDishUpSlipGroupsQueryVariables = Types.Exact<{
  dishUpSlipGroupId: Types.Scalars['Int'];
}>;


export type EditDishUpSlipGroupGetDishUpSlipGroupsQuery = (
  { __typename?: 'query_root' }
  & { dishUpSlipGroup: Array<(
    { __typename?: 'dishUpSlipGroup' }
    & Pick<Types.DishUpSlipGroup, 'dishUpSlipGroupId' | 'name' | 'shopId'>
    & { id: Types.DishUpSlipGroup['serial'] }
    & { shop: (
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'companyId'>
    ), dishUpSlipGroupRoles: Array<(
      { __typename?: 'dishUpSlipGroupRoles' }
      & Pick<Types.DishUpSlipGroupRoles, 'roleId'>
      & { dishUpSlipGroupId: Types.DishUpSlipGroupRoles['_dishUpSlipGroupId'] }
    )> }
  )> }
);

export type EditDishUpSlipGroupGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditDishUpSlipGroupGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
      & { shop: (
        { __typename?: 'shop' }
        & { dishUpSlipGroups: Array<(
          { __typename?: 'dishUpSlipGroup' }
          & Pick<Types.DishUpSlipGroup, 'name'>
          & { id: Types.DishUpSlipGroup['serial'] }
        )> }
      ) }
    )> }
  )> }
);

export type EditDishUpSlipGroupUpdateDishUpSlipGroupMutationVariables = Types.Exact<{
  dishUpSlipGroupId: Types.Scalars['Int'];
  dishUpSlipGroup: Types.DishUpSlipGroupSetInput;
  roleIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
  dishUpSlipGroupRoles: Array<Types.DishUpSlipGroupRolesInsertInput> | Types.DishUpSlipGroupRolesInsertInput;
}>;


export type EditDishUpSlipGroupUpdateDishUpSlipGroupMutation = (
  { __typename?: 'mutation_root' }
  & { update_dishUpSlipGroup?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroup_mutation_response' }
    & Pick<Types.DishUpSlipGroupMutationResponse, 'affected_rows'>
  )>, delete_dishUpSlipGroupRoles?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroupRoles_mutation_response' }
    & Pick<Types.DishUpSlipGroupRolesMutationResponse, 'affected_rows'>
  )>, insert_dishUpSlipGroupRoles?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroupRoles_mutation_response' }
    & Pick<Types.DishUpSlipGroupRolesMutationResponse, 'affected_rows'>
  )> }
);


export const EditDishUpSlipGroupGetDishUpSlipGroupsDocument = gql`
    query EditDishUpSlipGroupGetDishUpSlipGroups($dishUpSlipGroupId: Int!) {
  dishUpSlipGroup(
    where: {serial: {_eq: $dishUpSlipGroupId}, shop: {archivedAt: {_is_null: true}}}
  ) {
    id: serial
    dishUpSlipGroupId
    name
    shopId
    shop {
      companyId
    }
    dishUpSlipGroupRoles {
      roleId
      dishUpSlipGroupId: _dishUpSlipGroupId
    }
  }
}
    `;

/**
 * __useEditDishUpSlipGroupGetDishUpSlipGroupsQuery__
 *
 * To run a query within a React component, call `useEditDishUpSlipGroupGetDishUpSlipGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDishUpSlipGroupGetDishUpSlipGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDishUpSlipGroupGetDishUpSlipGroupsQuery({
 *   variables: {
 *      dishUpSlipGroupId: // value for 'dishUpSlipGroupId'
 *   },
 * });
 */
export function useEditDishUpSlipGroupGetDishUpSlipGroupsQuery(baseOptions: Apollo.QueryHookOptions<EditDishUpSlipGroupGetDishUpSlipGroupsQuery, EditDishUpSlipGroupGetDishUpSlipGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDishUpSlipGroupGetDishUpSlipGroupsQuery, EditDishUpSlipGroupGetDishUpSlipGroupsQueryVariables>(EditDishUpSlipGroupGetDishUpSlipGroupsDocument, options);
      }
export function useEditDishUpSlipGroupGetDishUpSlipGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDishUpSlipGroupGetDishUpSlipGroupsQuery, EditDishUpSlipGroupGetDishUpSlipGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDishUpSlipGroupGetDishUpSlipGroupsQuery, EditDishUpSlipGroupGetDishUpSlipGroupsQueryVariables>(EditDishUpSlipGroupGetDishUpSlipGroupsDocument, options);
        }
export type EditDishUpSlipGroupGetDishUpSlipGroupsQueryHookResult = ReturnType<typeof useEditDishUpSlipGroupGetDishUpSlipGroupsQuery>;
export type EditDishUpSlipGroupGetDishUpSlipGroupsLazyQueryHookResult = ReturnType<typeof useEditDishUpSlipGroupGetDishUpSlipGroupsLazyQuery>;
export type EditDishUpSlipGroupGetDishUpSlipGroupsQueryResult = Apollo.QueryResult<EditDishUpSlipGroupGetDishUpSlipGroupsQuery, EditDishUpSlipGroupGetDishUpSlipGroupsQueryVariables>;
export const EditDishUpSlipGroupGetRolesDocument = gql`
    query EditDishUpSlipGroupGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(where: {shop: {archivedAt: {_is_null: true}}}, order_by: {roleId: asc}) {
      id
      name
      roleId
      shop {
        dishUpSlipGroups {
          id: serial
          name
        }
      }
    }
  }
}
    `;

/**
 * __useEditDishUpSlipGroupGetRolesQuery__
 *
 * To run a query within a React component, call `useEditDishUpSlipGroupGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDishUpSlipGroupGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDishUpSlipGroupGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditDishUpSlipGroupGetRolesQuery(baseOptions: Apollo.QueryHookOptions<EditDishUpSlipGroupGetRolesQuery, EditDishUpSlipGroupGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDishUpSlipGroupGetRolesQuery, EditDishUpSlipGroupGetRolesQueryVariables>(EditDishUpSlipGroupGetRolesDocument, options);
      }
export function useEditDishUpSlipGroupGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDishUpSlipGroupGetRolesQuery, EditDishUpSlipGroupGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDishUpSlipGroupGetRolesQuery, EditDishUpSlipGroupGetRolesQueryVariables>(EditDishUpSlipGroupGetRolesDocument, options);
        }
export type EditDishUpSlipGroupGetRolesQueryHookResult = ReturnType<typeof useEditDishUpSlipGroupGetRolesQuery>;
export type EditDishUpSlipGroupGetRolesLazyQueryHookResult = ReturnType<typeof useEditDishUpSlipGroupGetRolesLazyQuery>;
export type EditDishUpSlipGroupGetRolesQueryResult = Apollo.QueryResult<EditDishUpSlipGroupGetRolesQuery, EditDishUpSlipGroupGetRolesQueryVariables>;
export const EditDishUpSlipGroupUpdateDishUpSlipGroupDocument = gql`
    mutation EditDishUpSlipGroupUpdateDishUpSlipGroup($dishUpSlipGroupId: Int!, $dishUpSlipGroup: dishUpSlipGroup_set_input!, $roleIds: [uuid!]!, $dishUpSlipGroupRoles: [dishUpSlipGroupRoles_insert_input!]!) {
  update_dishUpSlipGroup(
    _set: $dishUpSlipGroup
    where: {serial: {_eq: $dishUpSlipGroupId}}
  ) {
    affected_rows
  }
  delete_dishUpSlipGroupRoles(
    where: {_dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}, roleId: {_nin: $roleIds}}
  ) {
    affected_rows
  }
  insert_dishUpSlipGroupRoles(
    objects: $dishUpSlipGroupRoles
    on_conflict: {constraint: dishUpSlipGroupRoles_roleId_dishUpSlipGroupId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditDishUpSlipGroupUpdateDishUpSlipGroupMutationFn = Apollo.MutationFunction<EditDishUpSlipGroupUpdateDishUpSlipGroupMutation, EditDishUpSlipGroupUpdateDishUpSlipGroupMutationVariables>;

/**
 * __useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation__
 *
 * To run a mutation, you first call `useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDishUpSlipGroupUpdateDishUpSlipGroupMutation, { data, loading, error }] = useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation({
 *   variables: {
 *      dishUpSlipGroupId: // value for 'dishUpSlipGroupId'
 *      dishUpSlipGroup: // value for 'dishUpSlipGroup'
 *      roleIds: // value for 'roleIds'
 *      dishUpSlipGroupRoles: // value for 'dishUpSlipGroupRoles'
 *   },
 * });
 */
export function useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation(baseOptions?: Apollo.MutationHookOptions<EditDishUpSlipGroupUpdateDishUpSlipGroupMutation, EditDishUpSlipGroupUpdateDishUpSlipGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDishUpSlipGroupUpdateDishUpSlipGroupMutation, EditDishUpSlipGroupUpdateDishUpSlipGroupMutationVariables>(EditDishUpSlipGroupUpdateDishUpSlipGroupDocument, options);
      }
export type EditDishUpSlipGroupUpdateDishUpSlipGroupMutationHookResult = ReturnType<typeof useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation>;
export type EditDishUpSlipGroupUpdateDishUpSlipGroupMutationResult = Apollo.MutationResult<EditDishUpSlipGroupUpdateDishUpSlipGroupMutation>;
export type EditDishUpSlipGroupUpdateDishUpSlipGroupMutationOptions = Apollo.BaseMutationOptions<EditDishUpSlipGroupUpdateDishUpSlipGroupMutation, EditDishUpSlipGroupUpdateDishUpSlipGroupMutationVariables>;