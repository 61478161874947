import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddCouponInsertCoupon = gql`
    mutation AddCouponInsertCoupon($coupon: coupon_insert_input!) {
  insert_coupon_one(object: $coupon) {
    id
  }
}
    `;
export const AddCouponGetMenus = gql`
    query AddCouponGetMenus($companyId: uuid!) {
  menu(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    id
    menuId
    name
    price
    taxMethod
    taxRate
  }
}
    `;
export const CopyCouponGetCoupon = gql`
    query CopyCouponGetCoupon($id: uuid!) {
  coupon_by_pk(id: $id) {
    id
    name
    title
    availableFrom
    availableUntil
    availableDays
    description
    termsOfUse
    content
    imageUrl
    couponType
    couponMenuDiscount {
      menuId: _menuId
      discountedPrice
    }
  }
}
    `;
export type AddCouponInsertCouponMutationVariables = Types.Exact<{
  coupon: Types.CouponInsertInput;
}>;


export type AddCouponInsertCouponMutation = (
  { __typename?: 'mutation_root' }
  & { insert_coupon_one?: Types.Maybe<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id'>
  )> }
);

export type AddCouponGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddCouponGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'name' | 'price' | 'taxMethod' | 'taxRate'>
  )> }
);

export type CopyCouponGetCouponQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type CopyCouponGetCouponQuery = (
  { __typename?: 'query_root' }
  & { coupon_by_pk?: Types.Maybe<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name' | 'title' | 'availableFrom' | 'availableUntil' | 'availableDays' | 'description' | 'termsOfUse' | 'content' | 'imageUrl' | 'couponType'>
    & { couponMenuDiscount?: Types.Maybe<(
      { __typename?: 'couponMenuDiscount' }
      & Pick<Types.CouponMenuDiscount, 'discountedPrice'>
      & { menuId: Types.CouponMenuDiscount['_menuId'] }
    )> }
  )> }
);


export const AddCouponInsertCouponDocument = gql`
    mutation AddCouponInsertCoupon($coupon: coupon_insert_input!) {
  insert_coupon_one(object: $coupon) {
    id
  }
}
    `;
export type AddCouponInsertCouponMutationFn = Apollo.MutationFunction<AddCouponInsertCouponMutation, AddCouponInsertCouponMutationVariables>;

/**
 * __useAddCouponInsertCouponMutation__
 *
 * To run a mutation, you first call `useAddCouponInsertCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCouponInsertCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCouponInsertCouponMutation, { data, loading, error }] = useAddCouponInsertCouponMutation({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useAddCouponInsertCouponMutation(baseOptions?: Apollo.MutationHookOptions<AddCouponInsertCouponMutation, AddCouponInsertCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCouponInsertCouponMutation, AddCouponInsertCouponMutationVariables>(AddCouponInsertCouponDocument, options);
      }
export type AddCouponInsertCouponMutationHookResult = ReturnType<typeof useAddCouponInsertCouponMutation>;
export type AddCouponInsertCouponMutationResult = Apollo.MutationResult<AddCouponInsertCouponMutation>;
export type AddCouponInsertCouponMutationOptions = Apollo.BaseMutationOptions<AddCouponInsertCouponMutation, AddCouponInsertCouponMutationVariables>;
export const AddCouponGetMenusDocument = gql`
    query AddCouponGetMenus($companyId: uuid!) {
  menu(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    id
    menuId
    name
    price
    taxMethod
    taxRate
  }
}
    `;

/**
 * __useAddCouponGetMenusQuery__
 *
 * To run a query within a React component, call `useAddCouponGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddCouponGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddCouponGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddCouponGetMenusQuery(baseOptions: Apollo.QueryHookOptions<AddCouponGetMenusQuery, AddCouponGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddCouponGetMenusQuery, AddCouponGetMenusQueryVariables>(AddCouponGetMenusDocument, options);
      }
export function useAddCouponGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddCouponGetMenusQuery, AddCouponGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddCouponGetMenusQuery, AddCouponGetMenusQueryVariables>(AddCouponGetMenusDocument, options);
        }
export type AddCouponGetMenusQueryHookResult = ReturnType<typeof useAddCouponGetMenusQuery>;
export type AddCouponGetMenusLazyQueryHookResult = ReturnType<typeof useAddCouponGetMenusLazyQuery>;
export type AddCouponGetMenusQueryResult = Apollo.QueryResult<AddCouponGetMenusQuery, AddCouponGetMenusQueryVariables>;
export const CopyCouponGetCouponDocument = gql`
    query CopyCouponGetCoupon($id: uuid!) {
  coupon_by_pk(id: $id) {
    id
    name
    title
    availableFrom
    availableUntil
    availableDays
    description
    termsOfUse
    content
    imageUrl
    couponType
    couponMenuDiscount {
      menuId: _menuId
      discountedPrice
    }
  }
}
    `;

/**
 * __useCopyCouponGetCouponQuery__
 *
 * To run a query within a React component, call `useCopyCouponGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyCouponGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyCouponGetCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyCouponGetCouponQuery(baseOptions: Apollo.QueryHookOptions<CopyCouponGetCouponQuery, CopyCouponGetCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyCouponGetCouponQuery, CopyCouponGetCouponQueryVariables>(CopyCouponGetCouponDocument, options);
      }
export function useCopyCouponGetCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyCouponGetCouponQuery, CopyCouponGetCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyCouponGetCouponQuery, CopyCouponGetCouponQueryVariables>(CopyCouponGetCouponDocument, options);
        }
export type CopyCouponGetCouponQueryHookResult = ReturnType<typeof useCopyCouponGetCouponQuery>;
export type CopyCouponGetCouponLazyQueryHookResult = ReturnType<typeof useCopyCouponGetCouponLazyQuery>;
export type CopyCouponGetCouponQueryResult = Apollo.QueryResult<CopyCouponGetCouponQuery, CopyCouponGetCouponQueryVariables>;