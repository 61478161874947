import { useCallback } from "react";
import { taxRates } from "models/taxRate";

import { createFormItem, Form } from "components/antd/Form";
import { Category, CategoryMenu, Menu } from "pages/EditMenu/types";
import {
  CategoryMenuInsertInput,
  DisplayTypeEnum,
  MenuSetInput,
  TaxMethodEnum,
} from "types/graphql";

import { PlanInfo } from ".";

export const DEFAULT_CATEGORY_ID = -1;

export type EditMenuFormValues = Pick<
  Menu,
  | "description"
  | "featuredLabelText"
  | "imageUrl"
  | "orderableTimeId"
  | "name"
  | "orderMaxNum"
  | "orderMaxNumPerTableUser"
  | "receiptDisplayName"
  | "shopSideName"
  | "taxMethod"
  | "taxRate"
  | "unitMaxOrderNumForNumPeople"
  | "unitMaxOrderNumPerCustomer"
  | "openPrice"
  | "shouldMergeSlipOptions"
  | "costPrice"
  | "costTaxRate"
  | "isTakeOut"
  | "menuType"
> & {
  price: number;
  categoryIds: CategoryMenu["categoryId"][];
  displayTypes: Record<number, CategoryMenu["displayType"]>;
} & {
  hasOrderLimit: boolean;
  hasOrderLimitPerTableUser: boolean;
  hasOrderLimitForNumPeople: boolean;
  hasOrderLimitPerCustomer: boolean;
};

const getPriority = (categories: Category[], categoryId: number) => {
  const category = categories.find((category) => category.categoryId === categoryId);
  const priorities = category?.categoryMenus.flatMap(({ priority }) => priority) ?? [];
  return Math.max(-1, ...priorities) + 1;
};
const getInitialValues = (menu: Menu) => {
  const {
    categoryMenus,
    description,
    featuredLabelText,
    imageUrl,
    orderableTimeId,
    name,
    openPrice,
    orderMaxNum,
    orderMaxNumPerTableUser,
    receiptDisplayName,
    shopSideName,
    taxMethod,
    taxRate,
    unitMaxOrderNumForNumPeople,
    unitMaxOrderNumPerCustomer,
    shouldMergeSlipOptions,
    costPrice,
    costTaxRate,
    isTakeOut,
    menuType,
    price,
  } = menu;

  return {
    description,
    featuredLabelText,
    imageUrl: imageUrl ?? null,
    orderableTimeId,
    name,
    hasOrderLimit: typeof orderMaxNum === "number",
    hasOrderLimitPerTableUser: typeof orderMaxNumPerTableUser === "number",
    orderMaxNum,
    orderMaxNumPerTableUser,
    receiptDisplayName,
    openPrice,
    shopSideName,
    taxMethod,
    // NOTE: 税種別が「非課税」の場合は、税率フィールドに0%が設定され非活性となる。
    //       編集中に「非課税」以外を選択すると税率フィールドを0%のまま更新できてしまうので、初期値を設定する。
    //       テイクアウトより店内飲食をする商品の方が圧倒的に多いので、初期値は10%とする
    taxRate: taxMethod === TaxMethodEnum.NonTaxable ? taxRates["0.1"] : taxRate,
    hasOrderLimitForNumPeople: typeof unitMaxOrderNumForNumPeople === "number",
    hasOrderLimitPerCustomer: typeof unitMaxOrderNumPerCustomer === "number",
    unitMaxOrderNumForNumPeople,
    unitMaxOrderNumPerCustomer,
    shouldMergeSlipOptions,
    categoryIds: categoryMenus.map(({ categoryId }) => categoryId),
    displayTypes: Object.fromEntries(
      categoryMenus.map((categoryMenu) => [categoryMenu.categoryId, categoryMenu.displayType]),
    ),
    // NOTE: price=undefined なら prices から価格を取得する
    // https://www.notion.so/diniinote/08e2653f005e43a0ae3ca99064f46985
    price,
    costPrice,
    costTaxRate,
    isTakeOut,
    menuType,
  };
};

export const EditMenuFormItem = createFormItem<EditMenuFormValues>();

export const useEditMenuForm = (
  menu: Menu,
  categories: Category[],
  onSubmit: ({
    menu,
    categoryMenus,
  }: {
    menu: MenuSetInput;
    categoryMenus: CategoryMenuInsertInput[];
  }) => void,
  setRemovedPlansWithFirstOrder: React.Dispatch<React.SetStateAction<PlanInfo>>,
) => {
  const [form] = Form.useForm<EditMenuFormValues>();
  const initialValues = getInitialValues(menu);

  const submit = useCallback(
    ({ imageUrl }: { imageUrl: string | null | undefined }) => {
      const categoryMenuIdMap = Object.fromEntries(
        menu.categoryMenus.map(({ categoryId, categoryMenuId }) => [categoryId, categoryMenuId]),
      );
      const priorityMap = Object.fromEntries(
        menu.categoryMenus.map(({ categoryId, priority }) => [categoryId, priority]),
      );
      const formValues = form.getFieldsValue();

      const {
        categoryIds,
        displayTypes = {},
        price,
        hasOrderLimit,
        hasOrderLimitPerTableUser,
        hasOrderLimitForNumPeople,
        hasOrderLimitPerCustomer,
        receiptDisplayName,
        shopSideName,
        ...values
      } = formValues;
      const categoryMenus = categoryIds.map((_categoryId) => {
        const category = categories.find((category) => category.categoryId === _categoryId);

        if (!category) throw new Error("category not found");

        return {
          serial: categoryMenuIdMap[_categoryId] ?? undefined,
          menuId: menu.id,
          _menuId: menu.menuId,
          categoryId: category.id,
          _categoryId: category.categoryId,
          displayType: displayTypes[_categoryId],
          priority: priorityMap[_categoryId] ?? getPriority(categories, _categoryId),
        };
      });
      const orderMaxNum = !hasOrderLimit ? null : values.orderMaxNum;
      const orderMaxNumPerTableUser = !hasOrderLimitPerTableUser
        ? null
        : values.orderMaxNumPerTableUser;
      const unitMaxOrderNumForNumPeople = !hasOrderLimitForNumPeople
        ? null
        : values.unitMaxOrderNumForNumPeople;

      const unitMaxOrderNumPerCustomer = !hasOrderLimitPerCustomer
        ? null
        : values.unitMaxOrderNumPerCustomer;

      onSubmit({
        menu: {
          ...values,
          receiptDisplayName: receiptDisplayName || values.name,
          shopSideName: shopSideName || values.name,
          imageUrl,
          orderMaxNum,
          orderMaxNumPerTableUser,
          unitMaxOrderNumForNumPeople,
          unitMaxOrderNumPerCustomer,
          price,
          // NOTE: 税種別を非課税にしていた場合は税率を 0 とする
          taxRate: values.taxMethod === TaxMethodEnum.NonTaxable ? 0 : values.taxRate,
        },
        categoryMenus,
      });
    },
    [menu.categoryMenus, menu.id, menu.menuId, form, onSubmit, categories],
  );

  const updateDisplayTypesWithCategoryIds = useCallback(
    (changedValues: Partial<EditMenuFormValues>) => {
      const changedCategoryIds = changedValues.categoryIds;

      if (!changedCategoryIds) return;

      if (changedCategoryIds.length === 0) {
        form.setFieldsValue({ displayTypes: { [DEFAULT_CATEGORY_ID]: DisplayTypeEnum.Text } });

        return;
      }

      const currentDisplayTypes = form.getFieldsValue().displayTypes;

      form.setFieldsValue({
        displayTypes: Object.fromEntries(
          changedCategoryIds.map((categoryId) => [
            categoryId,
            currentDisplayTypes[DEFAULT_CATEGORY_ID] ??
              currentDisplayTypes[categoryId] ??
              DisplayTypeEnum.Text,
          ]),
        ),
      });
    },
    [form],
  );

  // NOTE: 既存のカテゴリを削除する場合は、プランの自動注文設定が存在するかチェックする
  // 存在すれば確認モーダルを表示する
  const checkFirstOrderMenuAffect = useCallback(
    (nextValues: Partial<EditMenuFormValues>) => {
      const changedCategoryIds = nextValues.categoryIds;
      if (!changedCategoryIds) return;

      const categoryIdsToRemove = initialValues.categoryIds.filter(
        (c) => !changedCategoryIds.includes(c),
      );
      if (!categoryIdsToRemove.length) return;

      const categoryMenusToRemove = menu.categoryMenus.filter((c) =>
        categoryIdsToRemove.includes(c.categoryId),
      );
      const removedPlansWithFirstOrder = Array.from(
        new Map(
          categoryMenusToRemove
            .flatMap(({ planFirstOrderCategoryMenus }) =>
              planFirstOrderCategoryMenus.map(({ plan }) => ({
                id: plan.id,
                planName: plan.planName,
              })),
            )
            .map((planInfo) => [planInfo.id, planInfo]),
        ).values(),
      );

      if (removedPlansWithFirstOrder.length > 0) {
        setRemovedPlansWithFirstOrder(removedPlansWithFirstOrder);
      }
    },
    [initialValues.categoryIds, menu.categoryMenus, setRemovedPlansWithFirstOrder],
  );

  return {
    form,
    initialValues,
    submit,
    updateDisplayTypesWithCategoryIds,
    checkFirstOrderMenuAffect,
  };
};
