import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Modal, Table } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { keyBy } from "lodash";

import { Spacer } from "components/Spacer";

import { ValidateMenuResult, ValidateOptionResult } from "../../../models/menuMasterCsv";

import { ActiveShopsConfirmationModal } from "./ActiveShopsConfirmationModal";
import {
  deletingMenusColumns,
  updatingMenusColumns,
  updatingOptionsColumns,
  ValidatedCsvOptionWithChanges,
} from "./columnDefinitions";
import { useUploadMenuCsvVerifyGetActiveShopsQuery } from "./queries";

type Props = {
  companyId: string | null;
  addingMenus: ValidateMenuResult["addingMenus"];
  updatingMenus: ValidateMenuResult["updatingMenus"];
  deletingMenus: ValidateMenuResult["deletingMenus"];
  addingOptions: ValidateOptionResult["addingOptions"];
  updatingOptions: ValidateOptionResult["updatingOptions"];
  deletingOptions: ValidateOptionResult["deletingOptions"];
  submit: () => void;
  closeModal: () => void;
};

const ModalTitle = styled.div`
  font-size: 18px;
`;

const TableTitle = styled.h3`
  font-size: 16px;
`;

const ContentWrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`;

const ConflictWarningText = styled.span`
  color: #ff4d4f;
`;

export const UploadMenuCsvVerifyModal = memo(
  ({
    companyId,
    addingMenus,
    updatingMenus,
    deletingMenus,
    addingOptions,
    updatingOptions,
    deletingOptions,
    submit,
    closeModal,
  }: Props) => {
    const [showingDuringHoursConfirmModal, setShowingDuringHoursConfirmModal] = useState(false);

    const { data: activeShopsData, loading: loadingShopsData } =
      useUploadMenuCsvVerifyGetActiveShopsQuery(
        companyId ? { variables: { companyId } } : { skip: true },
      );

    const activeShops = useMemo(() => activeShopsData?.shop ?? [], [activeShopsData]);
    const hasActivishTableUsers = activeShops.length > 0;

    const updatingMenuRows = useMemo(
      () =>
        updatingMenus
          .filter(
            ({
              changingMenuColumns,
              changingTranslations,
              to: { addingCategoryMenus, removingCategoryMenus },
            }) =>
              changingMenuColumns.length > 0 ||
              changingTranslations.length > 0 ||
              addingCategoryMenus.length ||
              removingCategoryMenus.length,
          )
          .map(({ isConflicted, changingMenuColumns, changingTranslations, to }) => ({
            ...to,
            changingMenuColumns,
            changingTranslations,
            isConflicted,
          })),
      [updatingMenus],
    );

    const creatingOptionRows: ValidatedCsvOptionWithChanges[] = useMemo(
      () =>
        addingOptions.map((option) => ({
          ...option,
          children: option.choices,
          changingOptionColumns: [],
          changingTranslations: [],
        })),
      [addingOptions],
    );

    const updatingOptionRows: ValidatedCsvOptionWithChanges[] = useMemo(
      () =>
        updatingOptions
          .filter(
            ({ addingChoices, changingOptionColumns, changingChoices, changingTranslations }) =>
              addingChoices.length > 0 ||
              changingOptionColumns.length > 0 ||
              changingTranslations.length > 0 ||
              changingChoices.length > 0,
          )
          .map(
            ({
              isConflicted,
              changingOptionColumns,
              changingTranslations,
              changingChoices,
              to,
            }) => {
              const choiceIdToChangingChoiceMap = keyBy(
                changingChoices,
                ({ _choiceId }) => _choiceId,
              );

              return {
                ...to,
                children: to.choices.map((choice) => {
                  const changingChoice = choice._choiceId
                    ? choiceIdToChangingChoiceMap[choice._choiceId]
                    : null;

                  return {
                    ...choice,
                    changingChoiceColumns: changingChoice?.changingColumns ?? [],
                  };
                }),
                changingOptionColumns,
                changingTranslations,
                isConflicted,
              };
            },
          ),
      [updatingOptions],
    );

    const handleSubmit = useCallback(() => {
      if (hasActivishTableUsers) {
        setShowingDuringHoursConfirmModal(true);
        return;
      }

      submit();
    }, [hasActivishTableUsers, submit]);

    const handleCloseDuringHoursConfirmModal = useCallback(
      () => setShowingDuringHoursConfirmModal(false),
      [],
    );

    const handleConfirmDuringHoursUpdate = useCallback(() => {
      setShowingDuringHoursConfirmModal(false);
      submit();
    }, [submit]);

    const hasConflictedUpdatingMenu = useMemo(
      () => updatingMenuRows.some(({ isConflicted }) => isConflicted),
      [updatingMenuRows],
    );

    return (
      <>
        <Modal
          width="100%"
          title={<ModalTitle>変更内容確認</ModalTitle>}
          zIndex={1002}
          centered
          closable={false}
          open
          footer={
            <>
              <Button type="primary" loading={loadingShopsData} onClick={handleSubmit}>
                メニューを一括更新
              </Button>
              <Button onClick={closeModal}>キャンセル</Button>
            </>
          }
          onCancel={closeModal}
        >
          <ContentWrapper>
            <Spacer size={16} />
            <TableTitle>追加対象のメニュー {addingMenus.length}件</TableTitle>
            <Table scroll={{ x: 3500 }} dataSource={addingMenus} columns={updatingMenusColumns} />

            <Spacer size={16} />
            <TableTitle>更新対象のメニュー {updatingMenuRows.length}件</TableTitle>
            {hasConflictedUpdatingMenu ? (
              <>
                <ConflictWarningText>
                  <ExclamationCircleOutlined />
                  <Spacer size={5} inline />
                  アイコンが表示されているメニューは、CSVファイルのダウンロード後に、メニューが更新されています。更新された項目（ピンク色の箇所）をご確認ください。
                </ConflictWarningText>
                <Spacer size={5} />
              </>
            ) : null}
            <Table
              defaultExpandAllRows={false}
              scroll={{ x: 3500 }}
              dataSource={updatingMenuRows}
              columns={updatingMenusColumns}
              rowKey={({ _menuId }) => `existing-menu-${_menuId}`}
            />

            <Spacer size={16} />
            <TableTitle>削除対象のメニュー {deletingMenus.length}件</TableTitle>
            <Table dataSource={deletingMenus} columns={deletingMenusColumns} />

            <Spacer size={16} />
            <TableTitle>追加対象のオプション {creatingOptionRows.length}件</TableTitle>
            <Table
              scroll={{ x: 3500 }}
              dataSource={creatingOptionRows}
              columns={updatingOptionsColumns}
              rowKey={(row) => `${row.csvRowNumber}-${row.name}`}
            />

            <Spacer size={16} />
            <TableTitle>更新対象のオプション {updatingOptionRows.length}件</TableTitle>
            {hasConflictedUpdatingMenu ? (
              <>
                <ConflictWarningText>
                  <ExclamationCircleOutlined />
                  <Spacer size={5} inline />
                  アイコンが表示されているオプションは、CSVファイルのダウンロード後に、オプションが更新されています。更新された項目（ピンク色の箇所）をご確認ください。
                </ConflictWarningText>
                <Spacer size={5} />
              </>
            ) : null}
            <Table
              scroll={{ x: 3500 }}
              dataSource={updatingOptionRows}
              columns={updatingOptionsColumns}
              rowKey={(row) => `${row.csvRowNumber}-${row.name}`}
            />
          </ContentWrapper>
        </Modal>
        <ActiveShopsConfirmationModal
          open={showingDuringHoursConfirmModal && hasActivishTableUsers}
          activeShops={activeShops}
          onCancel={handleCloseDuringHoursConfirmModal}
          onSubmit={handleConfirmDuringHoursUpdate}
        />
      </>
    );
  },
);
