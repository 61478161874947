import React, { memo, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { InputNumber as AntdInputNumber, InputNumberProps } from "antd";
import { halfWidthNumberToFullWidthNumber } from "util/charWidth";

const StyledInputNumber = styled(AntdInputNumber)<Props>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  display: ${({ fullWidth }) => (fullWidth ? "block" : "inline-block")};

  input {
    text-align: right;
    padding-right: calc(22px + 4px); /* stepAreaWidth + paddingRight */
  }
`;

export type Props = InputNumberProps & {
  fullWidth?: boolean;
  suffix?: string;
};

export const InputNumber = memo<Props>(
  ({
    formatter: originalFormatter,
    parser: originalParser,
    onFocus: originalOnFocus,
    onKeyDown: originalOnKeyDown,
    onChange: originalOnChange,
    onBlurCapture: originalOnBlurCapture,
    onBlur: originalOnBlur,
    suffix,
    ...props
  }) => {
    const ref = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const currentValue = props.value;

    const formatter: NonNullable<InputNumberProps["formatter"]> = useCallback(
      (value = "", info) => {
        const convertedValue =
          typeof value === "string" ? halfWidthNumberToFullWidthNumber(value) : value;
        if (originalFormatter) return originalFormatter(convertedValue, info);
        return convertedValue.toString();
      },
      [originalFormatter],
    );

    const parser: NonNullable<InputNumberProps["parser"]> = useCallback(
      (displayValue) => {
        const convertedValue = halfWidthNumberToFullWidthNumber(displayValue ?? "");
        if (originalParser) return originalParser(convertedValue);
        return convertedValue;
      },
      [originalParser],
    );

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        originalOnFocus?.(e);
      },
      [originalOnFocus],
    );

    /**
     * NOTE: IME 入力確定時の Enter キー押下時の処理として使用。
     * → onPressEnter は IME 変換確定時にも発火するため使用しない。
     */
    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        // NOTE: IME 入力中の変換確定の Enter キー押下時は mac で値が2重に入らないようにするため value を null にする
        // ※ どの OS やブラウザの組み合わせでも、その後の値確定の Enter キー押下時や Blur 時に onChange が発火するため問題ない
        if (e.keyCode === 229 && e.key === "Enter") {
          originalOnChange?.(null);
        }

        // NOTE: 値確定の Enter キー押下時
        if (e.keyCode === 13 && parser(e.currentTarget.value) !== currentValue) {
          // NOTE: blur させることでonChangeを発火させ、formatterを適用させる
          ref.current?.blur();
        }

        originalOnKeyDown?.(e);
      },
      [currentValue, originalOnChange, originalOnKeyDown, parser],
    );

    const handleBlurCapture = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);

        const parsedValue = parser(e.currentTarget.value);

        isNaN(Number(parsedValue))
          ? originalOnChange?.(null)
          : originalOnChange?.(Number(parsedValue));

        originalOnBlurCapture?.(e);
      },
      [originalOnBlurCapture, originalOnChange, parser],
    );

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        originalOnBlur?.(e);
      },
      [originalOnBlur],
    );

    return (
      <StyledInputNumber
        formatter={isFocused ? undefined : formatter}
        parser={isFocused ? undefined : parser}
        suffix={suffix}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onBlurCapture={handleBlurCapture}
        onBlur={handleBlur}
        onStep={originalOnChange}
        {...props}
        ref={ref}
      />
    );
  },
);
