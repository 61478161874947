import { useMemo } from "react";
import { getRole } from "models/user";

import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useCurrentUser } from "hooks/useUser";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";
import { Role } from "types/role";

export const useEditTecAggregationConfigFormPermission = () => {
  const user = useCurrentUser();
  const role = getRole(user);
  const { hasPermission } = useCurrentUserPermissions();

  const canEditForm = useMemo(() => role === Role.ServiceAdmin, [role]);
  const canToggleStatus = useMemo(
    () =>
      role === Role.ServiceAdmin ||
      hasPermission({
        feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
        type: DashboardAccountRolePermissionTypeEnum.Edit,
      }),
    [hasPermission, role],
  );

  return { canEditForm, canToggleStatus };
};
