import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children">;

export const IsTakeOutField = memo<Props>((props) => (
  <EditMenuFormItem
    name="isTakeOut"
    valuePropName="checked"
    help="テイクアウト、デリバリーなどの店外利用として売上集計します"
    {...props}
  >
    <Checkbox>店外メニューとして利用する</Checkbox>
  </EditMenuFormItem>
));
