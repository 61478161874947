import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuHeader } from "components/PageHeader/MenuHeader";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { EditMenuOptionForm } from "./EditMenuOptionForm";
import {
  MenuOptionsGetMenuOptionQuery,
  useMenuOptionsGetMenuOptionQuery,
  useMenuOptionsGetOptionsQuery,
  useMenuOptionsUpdateMenuOptionsMutation,
} from "./queries";

export const MenuOptions = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company, setCompany] = useCompany();

  const companyId = company?.id;

  const { id } = useParams<{ id: string }>();
  const _menuId = Number(id);
  const navigate = useNavigate();

  const setCompanyId = useCallback(
    (data: MenuOptionsGetMenuOptionQuery) => setCompany(data.menu?.[0]?.companyId),
    [setCompany],
  );

  const {
    data: getMenuOptionData,
    loading: loadingMenu,
    error: menuError,
    refetch: refetchMenuOption,
  } = useMenuOptionsGetMenuOptionQuery(
    !Number.isNaN(_menuId)
      ? { variables: { menuId: _menuId }, onCompleted: setCompanyId }
      : { skip: true },
  );

  const menu = getMenuOptionData?.menu?.[0];
  const menuId = menu?.id;

  const {
    data: getOptionData,
    loading: loadingOptions,
    error: optionsError,
    refetch: refetchOptionsData,
  } = useMenuOptionsGetOptionsQuery(companyId ? { variables: { companyId } } : { skip: true });

  const menuOptions = getMenuOptionData?.menuOption ?? [];
  const options = useMemo(() => getOptionData?.option ?? [], [getOptionData?.option]);

  const [insertOptionsMutation, { loading: loadingInsertingOptions }] =
    useMenuOptionsUpdateMenuOptionsMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onOptionsUpdate = useCallback(
    async ({ menuOptions }: { menuOptions: { priority: number; _optionId: number }[] }) => {
      try {
        if (!menuId || !_menuId) return;

        await insertOptionsMutation({
          variables: {
            menuId: _menuId,
            menuOptions: menuOptions.map((menuOption) => {
              const option = options.find(({ optionId }) => optionId === menuOption._optionId);
              if (!option) throw new Error("option not found");

              return {
                priority: menuOption.priority,
                optionId: option.id,
                _optionId: menuOption._optionId,
                menuId,
                _menuId,
              };
            }),
          },
        });

        await refetchMenuOption();

        message.success("編集を保存しました");
        goBack();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [_menuId, insertOptionsMutation, goBack, options, menuId, refetchMenuOption],
  );

  const isEditMenuFeatureEnabled = isFeatureEnabled("editMenu");
  const canEditAllShopMenus = menu?.totalDealingShopCount === menu?.shopMenus.length;

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }, ...(menu ? [{ name: menu.name }] : [])],
      }}
    >
      <MenuHeader
        menu={menu ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(menuError || optionsError)}
        shouldShowManagingShopErrorAlert={isEditMenuFeatureEnabled && !canEditAllShopMenus}
      />
      {loadingMenu && <Loading height={300} />}
      {(menuError || optionsError) && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {menu && options && (
        <EditMenuOptionForm
          menuOptions={menuOptions}
          options={options}
          onSubmit={onOptionsUpdate}
          onClose={goBack}
          loading={loadingMenu || loadingOptions || loadingInsertingOptions}
          disabled={!isEditMenuFeatureEnabled || !canEditAllShopMenus}
          loadOptions={refetchOptionsData}
        />
      )}
    </DashboardLayout>
  );
};
