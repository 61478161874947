import React, { useCallback, useMemo, useState } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Button, UploadFile } from "antd";
import { saveAs } from "file-saver";
import { uniq, uniqBy } from "lodash";
import { MasterCsvError, ValidateMenuResult } from "models/menuMasterCsv";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { ExternalLink } from "components/ExternalLink";
import { FormFileItem } from "components/Form/FormFileItem";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { helpPageUrl } from "constants/externalLinks";
import { useCompany } from "hooks/useCompany";
import { useDinii } from "hooks/useDinii";
import { MenuMasterUpdateResult } from "libs/dinii/rest";
import { unwrap } from "libs/unwrap";

import { MenuMasterLoadingModal } from "./MenuMasterLoadingModal";
import { useMenuMasterCsvGetCompanyShopsQuery } from "./queries";
import { UploadMenuCsvCompleteModal } from "./UploadMenuCsvCompleteModal";
import { UploadMenuCsvUploadFailureModal } from "./UploadMenuCsvUploadFailureModal";
import { UploadMenuCsvVerifyModal } from "./UploadMenuCsvVerifyModal";
import { VerifyMenuCsvFailureModal } from "./VerifyMenuCsvFailureModal";

const ContentHeader = styled.h2`
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FileDownloadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FileDownloadSection = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-self: flex-end;
`;

const DownloadFileTitle = styled.span`
  font-weight: bold;
`;

export enum MenuMasterCsvType {
  menu = "menu",
  option = "option",
}

const csvTypeRegex = /#master_csv_type:(menu|option)/;

const readCsvTypeFromFile = (file: UploadFile<any>): Promise<MenuMasterCsvType | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        const csvFirstLine = String(reader.result).split("\r\n")[0] ?? "";

        const matchResult = csvFirstLine.match(csvTypeRegex);

        if (matchResult?.[1] === MenuMasterCsvType.menu) resolve(MenuMasterCsvType.menu);
        if (matchResult?.[1] === MenuMasterCsvType.option) resolve(MenuMasterCsvType.option);

        resolve(null);
      },
      false,
    );

    if (file) {
      reader.readAsText(file.originFileObj as Blob);
    }
  });

export const MenuMasterCsv = () => {
  const [dinii, getContext] = useDinii();
  const [company] = useCompany();
  const { data } = useMenuMasterCsvGetCompanyShopsQuery(
    company
      ? {
          variables: {
            companyId: company.id,
          },
        }
      : {
          skip: true,
        },
  );
  const shopIds = useMemo(() => data?.shop.map(({ shopId }) => shopId) ?? [], [data]);

  const [{ loading: downloadingMenuCsv }, downloadMenuCsv] = useAsyncFn(async () => {
    const context = await getContext();
    if (!context || !company) return;
    try {
      const { data: csvText } = await dinii.menuMasterCsv.downloadMenuCsv({
        companyId: company.id,
        shopIds,
        context,
      });
      const blob = new Blob([csvText], { type: "text/csv;charset=utf-8" });

      saveAs(blob, `menu.csv`);
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [dinii, getContext, company, shopIds]);

  const [{ loading: downloadingOptionCsv }, downloadOptionCsv] = useAsyncFn(async () => {
    const context = await getContext();
    if (!context || !company) return;
    try {
      const { data: csvText } = await dinii.menuMasterCsv.downloadOptionCsv(company.id, context);
      const blob = new Blob([csvText], { type: "text/csv;charset=utf-8" });

      saveAs(blob, `option.csv`);
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [dinii, getContext, company]);

  const [validateMenuMasterResult, setValidateMenuMasterResult] =
    useState<ValidateMenuResult | null>(null);

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const closeVerifyModal = useCallback(() => setVerifyModalVisible(false), []);

  const [menuMasterFiles, setMenuMasterFiles] = useState<UploadFile<any>[]>([]);
  const [menuMasterCsvTypes, setMenuMasterCsvTypes] = useState<MenuMasterCsvType[]>([]);
  const [verificationErrors, setVerificationErrors] = useState<MasterCsvError[]>([]);
  const [menuMasterUpdateResult, setMenuMasterUpdateResult] =
    useState<MenuMasterUpdateResult | null>(null);

  const [updateMenuCompleteModalVisible, setUpdateMenuCompleteModalVisible] = useState(false);
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);
  const [loadingProgressPercentage, setLoadingProgressPercentage] = useState(0);

  const closeUpdateMenuCompleteModal = useCallback(() => {
    setUpdateMenuCompleteModalVisible(false);
    setMenuMasterFiles([]);
    setMenuMasterCsvTypes([]);
    setVerifyModalVisible(false);
    setValidateMenuMasterResult(null);
    setLoadingModalVisible(false);
  }, []);

  const isVerifyMenuFailureModalVisible = verificationErrors.length > 0;
  const closeVerifyMenuFailureModal = useCallback(() => setVerificationErrors([]), []);

  const [isUpdateMenuFailureModalVisible, setUpdateMenuFailureModalVisible] = useState(false);
  const showUpdateMenuFailureModal = useCallback(() => {
    setUpdateMenuFailureModalVisible(true);
    setLoadingModalVisible(false);
  }, []);
  const closeUpdateMenuFailureModal = useCallback(
    () => setUpdateMenuFailureModalVisible(false),
    [],
  );

  const [{ loading: verifyingCsv }, verifyCsv] = useAsyncFn(async () => {
    const filesWithType = await Promise.all(
      menuMasterFiles.map(async (file) => ({
        file: file.originFileObj,
        csvType: await readCsvTypeFromFile(file),
      })),
    );

    const hasInvalidFile = filesWithType.some(({ csvType }) => csvType === null);
    const hasDuplicateFileTypes =
      filesWithType.length !== uniqBy(filesWithType, ({ csvType }) => csvType).length;

    if (hasInvalidFile) {
      message.error(
        "内容の認識に失敗したファイルがあります。選択したファイルを再度ご確認ください。",
      );
      return;
    }

    if (hasDuplicateFileTypes) {
      message.error("同じ種類のファイルは複数指定できません");
      return;
    }

    const context = await getContext();

    if (!context || !company) {
      return;
    }

    try {
      const formData = new FormData();

      filesWithType.forEach((menuMasterFile) => {
        formData.append(unwrap(menuMasterFile.csvType), unwrap(menuMasterFile.file));
      });

      formData.append("companyId", company.id);

      setLoadingProgressPercentage(0);
      setLoadingModalVisible(true);

      const data = await dinii.menuMasterCsv.verify({
        context,
        data: formData,
        onError: () => {
          message.error("不明なエラーが発生しました");
          setLoadingModalVisible(false);
        },
        onProgress: ({ percentage }) => setLoadingProgressPercentage(percentage),
      });

      if (data.verificationResult.errors.length > 0) {
        setVerificationErrors(data.verificationResult.errors);
        setLoadingModalVisible(false);
        return;
      }

      setValidateMenuMasterResult({
        addingMenus: data.verificationResult.addingMenus ?? [],
        updatingMenus: data.verificationResult.updatingMenus ?? [],
        deletingMenus: data.verificationResult.deletingMenus ?? [],
        addingOptions: data.verificationResult.addingOptions ?? [],
        updatingOptions: data.verificationResult.updatingOptions ?? [],
        deletingOptions: [], //data.verificationResult.deletingOptions ?? [],
      });
      setVerifyModalVisible(true);
    } catch (err) {
      console.error(err);
      message.error("不明なエラーが発生しました");
    }
    setLoadingModalVisible(false);
  }, [getContext, company, dinii.menuMasterCsv, menuMasterFiles]);

  const [{ loading: uploadingCsv }, uploadCsv] = useAsyncFn(async () => {
    setLoadingProgressPercentage(0);
    const context = await getContext();

    const filesWithType = await Promise.all(
      menuMasterFiles.map(async (file) => ({
        file: file.originFileObj,
        csvType: await readCsvTypeFromFile(file),
      })),
    );

    if (!context || !company) return;

    setVerifyModalVisible(false);

    try {
      const formData = new FormData();

      filesWithType.forEach((menuMasterFile) => {
        formData.append(unwrap(menuMasterFile.csvType), unwrap(menuMasterFile.file));
      });

      formData.append("companyId", company.id);

      setLoadingModalVisible(true);

      const data = await dinii.menuMasterCsv.upload({
        context,
        data: formData,
        onError: () => {
          message.error("不明なエラーが発生しました");
          setLoadingModalVisible(false);
        },
        onProgress: ({ percentage }) => setLoadingProgressPercentage(percentage),
      });

      setMenuMasterUpdateResult(data);

      const shouldShowFailureModal =
        data === null || !data.menuResult.isSucceeded || !data.optionResult.isSucceeded;

      if (shouldShowFailureModal) {
        showUpdateMenuFailureModal();
        return;
      }

      setUpdateMenuCompleteModalVisible(true);
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
    setLoadingModalVisible(false);
  }, [company, dinii, getContext, showUpdateMenuFailureModal, menuMasterFiles]);

  const removeUploadedFile = useCallback(() => {
    setMenuMasterFiles([]);
    setVerificationErrors([]);
  }, []);

  const handleFileSelect = useCallback(async (files: UploadFile<any>[]) => {
    const csvTypes = await Promise.all(
      uniq(files.map(async (file) => await readCsvTypeFromFile(file))),
    );

    if (csvTypes.includes(null)) {
      message.error(
        "内容の認識に失敗したファイルがあります。選択したファイルを再度ご確認ください。",
      );
      return;
    }

    setMenuMasterFiles(files);
    // NOTE: 上に null が入ってないのを確認してるので配列に MenuMasterCsvType しか入ってないです
    setMenuMasterCsvTypes(csvTypes as MenuMasterCsvType[]);
  }, []);

  return (
    <DashboardLayout title="メニュー一括編集">
      <PageHeader title="メニュー一括編集" footer={null} />
      <Spacer size={12} />
      <p>メニューを一括で登録・編集することができます。</p>
      <p>手順に従って、CSVファイルをダウンロードして、編集後アップロードしてください。</p>
      <p>（CSVファイルのフォーマットは今後変更する可能性があります）</p>
      <Spacer size={16} />

      <FormContent>
        <ContentHeader>1. CSVファイルをダウンロード</ContentHeader>
        <Spacer size={24} />
        <p>
          初めてご利用の際には
          <ExternalLink externalLinkUrl={helpPageUrl} text="ヘルプサイト" />
          をご覧ください。
        </p>
        メニューマスターのCSVファイルをダウンロードしてください。
        <Spacer size={24} />
        <FileDownloadWrapper>
          <FileDownloadSection>
            <DownloadFileTitle>メニュー</DownloadFileTitle>
            <Spacer size={5} />
            <ButtonWrapper>
              <Button type="primary" onClick={downloadMenuCsv} disabled={downloadingMenuCsv}>
                ダウンロード
              </Button>
            </ButtonWrapper>
          </FileDownloadSection>
          <Spacer inline size={10} />
          <FileDownloadSection>
            <DownloadFileTitle>オプション</DownloadFileTitle>
            <Spacer size={5} />
            <ButtonWrapper>
              <Button type="primary" onClick={downloadOptionCsv} disabled={downloadingOptionCsv}>
                ダウンロード
              </Button>
            </ButtonWrapper>
          </FileDownloadSection>
        </FileDownloadWrapper>
      </FormContent>
      <Spacer size={24} />
      <FormContent>
        <ContentHeader>2. CSVファイルをアップロード</ContentHeader>
        <Spacer size={24} />
        <FormFileItem
          files={menuMasterFiles}
          acceptedTypes={["text/csv"]}
          targetFileLabel="メニューマスターCSV"
          onUpload={handleFileSelect}
          onRemove={removeUploadedFile}
          allowMultiple={true}
        />
      </FormContent>
      <FormContent>
        <ButtonWrapper>
          <Button
            type="primary"
            onClick={verifyCsv}
            disabled={menuMasterFiles.length === 0}
            loading={verifyingCsv}
          >
            アップロード内容の確認
          </Button>
        </ButtonWrapper>
      </FormContent>
      {verifyModalVisible && (
        <UploadMenuCsvVerifyModal
          companyId={company?.id ?? null}
          addingMenus={validateMenuMasterResult?.addingMenus ?? []}
          updatingMenus={validateMenuMasterResult?.updatingMenus ?? []}
          deletingMenus={validateMenuMasterResult?.deletingMenus ?? []}
          addingOptions={validateMenuMasterResult?.addingOptions ?? []}
          updatingOptions={validateMenuMasterResult?.updatingOptions ?? []}
          deletingOptions={validateMenuMasterResult?.deletingOptions ?? []}
          submit={uploadCsv}
          closeModal={closeVerifyModal}
        />
      )}
      <MenuMasterLoadingModal
        visible={loadingModalVisible}
        progressPercentage={loadingProgressPercentage}
      />
      <UploadMenuCsvCompleteModal
        visible={updateMenuCompleteModalVisible}
        csvTypes={menuMasterCsvTypes}
        closeModal={closeUpdateMenuCompleteModal}
      />
      <VerifyMenuCsvFailureModal
        visible={isVerifyMenuFailureModalVisible}
        errors={verificationErrors}
        closeModal={closeVerifyMenuFailureModal}
      />
      <UploadMenuCsvUploadFailureModal
        visible={isUpdateMenuFailureModalVisible}
        menuMasterUpdateResult={menuMasterUpdateResult}
        closeModal={closeUpdateMenuFailureModal}
      />
    </DashboardLayout>
  );
};
