import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopGetShop = gql`
    query EditShopGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    changeDateTime
    close
    companyId
    disableCallClerk
    enableAlert
    enableAutoResetMenuSoldOut
    enableRemark
    lastOrderText
    maxNumForNumPeople
    name
    open
    shopId
    enableCustomerSegmentSelection
    disableSelfAccountingStart
    offlinePaymentDescription
    useKd
    accountingActionType
    iconImageUrl
    enableDisplayTaxExcludedPriceForCustomer
    checkInElapsedTimeAlertThresholdMinutes
    lastOrderElapsedTimeAlertThresholdMinutes
  }
}
    `;
export const EditShopUpdateShop = gql`
    mutation EditShopUpdateShop($shopId: uuid!, $shop: shop_set_input!) {
  update_shop(
    _set: $shop
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
}
    `;
export type EditShopGetShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopGetShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'changeDateTime' | 'close' | 'companyId' | 'disableCallClerk' | 'enableAlert' | 'enableAutoResetMenuSoldOut' | 'enableRemark' | 'lastOrderText' | 'maxNumForNumPeople' | 'name' | 'open' | 'shopId' | 'enableCustomerSegmentSelection' | 'disableSelfAccountingStart' | 'offlinePaymentDescription' | 'useKd' | 'accountingActionType' | 'iconImageUrl' | 'enableDisplayTaxExcludedPriceForCustomer' | 'checkInElapsedTimeAlertThresholdMinutes' | 'lastOrderElapsedTimeAlertThresholdMinutes'>
  )> }
);

export type EditShopUpdateShopMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  shop: Types.ShopSetInput;
}>;


export type EditShopUpdateShopMutation = (
  { __typename?: 'mutation_root' }
  & { update_shop?: Types.Maybe<(
    { __typename?: 'shop_mutation_response' }
    & Pick<Types.ShopMutationResponse, 'affected_rows'>
  )> }
);


export const EditShopGetShopDocument = gql`
    query EditShopGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    changeDateTime
    close
    companyId
    disableCallClerk
    enableAlert
    enableAutoResetMenuSoldOut
    enableRemark
    lastOrderText
    maxNumForNumPeople
    name
    open
    shopId
    enableCustomerSegmentSelection
    disableSelfAccountingStart
    offlinePaymentDescription
    useKd
    accountingActionType
    iconImageUrl
    enableDisplayTaxExcludedPriceForCustomer
    checkInElapsedTimeAlertThresholdMinutes
    lastOrderElapsedTimeAlertThresholdMinutes
  }
}
    `;

/**
 * __useEditShopGetShopQuery__
 *
 * To run a query within a React component, call `useEditShopGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopGetShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopGetShopQuery(baseOptions: Apollo.QueryHookOptions<EditShopGetShopQuery, EditShopGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopGetShopQuery, EditShopGetShopQueryVariables>(EditShopGetShopDocument, options);
      }
export function useEditShopGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopGetShopQuery, EditShopGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopGetShopQuery, EditShopGetShopQueryVariables>(EditShopGetShopDocument, options);
        }
export type EditShopGetShopQueryHookResult = ReturnType<typeof useEditShopGetShopQuery>;
export type EditShopGetShopLazyQueryHookResult = ReturnType<typeof useEditShopGetShopLazyQuery>;
export type EditShopGetShopQueryResult = Apollo.QueryResult<EditShopGetShopQuery, EditShopGetShopQueryVariables>;
export const EditShopUpdateShopDocument = gql`
    mutation EditShopUpdateShop($shopId: uuid!, $shop: shop_set_input!) {
  update_shop(
    _set: $shop
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
}
    `;
export type EditShopUpdateShopMutationFn = Apollo.MutationFunction<EditShopUpdateShopMutation, EditShopUpdateShopMutationVariables>;

/**
 * __useEditShopUpdateShopMutation__
 *
 * To run a mutation, you first call `useEditShopUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopUpdateShopMutation, { data, loading, error }] = useEditShopUpdateShopMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      shop: // value for 'shop'
 *   },
 * });
 */
export function useEditShopUpdateShopMutation(baseOptions?: Apollo.MutationHookOptions<EditShopUpdateShopMutation, EditShopUpdateShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopUpdateShopMutation, EditShopUpdateShopMutationVariables>(EditShopUpdateShopDocument, options);
      }
export type EditShopUpdateShopMutationHookResult = ReturnType<typeof useEditShopUpdateShopMutation>;
export type EditShopUpdateShopMutationResult = Apollo.MutationResult<EditShopUpdateShopMutation>;
export type EditShopUpdateShopMutationOptions = Apollo.BaseMutationOptions<EditShopUpdateShopMutation, EditShopUpdateShopMutationVariables>;