import { useCallback, useEffect, useMemo, useState } from "react";
import constate from "constate";

import { useGetCompanyQuery } from "hooks/useCompany/queries";
import { useCorporation } from "hooks/useCorporation";
import { useStorage } from "hooks/useStorage";
import { useCurrentUser } from "hooks/useUser";
import { logger } from "libs/logger";
import { Maybe, PikaichiConfig, TecAggregationConfig, WinboardConfig } from "types/graphql";

export const [CompanyProvider, useCompany] = constate(() => {
  const [, setCorporation] = useCorporation();
  const [company, setCompany] = useState<{
    id: string;
    companyId: number;
    name: string;
    shops: {
      shopId: string;
      pikaichiConfig?: Maybe<Pick<PikaichiConfig, "pikaichiConfigId" | "enabled">>;
      tecAggregationConfigs?: Maybe<
        Pick<TecAggregationConfig, "id" | "enabled" | "integrationType">
      >[];
      winboardConfig?: Maybe<Pick<WinboardConfig, "winboardConfigId" | "enabled">>;
    }[];
  } | null>(null);
  const { addCompanyId } = useStorage();

  const currentUser = useCurrentUser();
  const { data: companyData, refetch } = useGetCompanyQuery(
    currentUser
      ? {
          fetchPolicy: "cache-and-network",
        }
      : { skip: true },
  );

  const handleSetCompany = useCallback(
    async (companyId?: string) => {
      if (companyId && companyId !== company?.id) {
        // TODO: useCorporation, useCompany, useShop を 1 つにまとめたい
        const companies = (companyData ?? (await refetch()).data).company;
        const company = companies.find((company) => company.id === companyId);
        if (!company) return;

        logger.addContext({ key: "companyId", value: companyId });
        logger.addContext({ key: "_companyId", value: company.companyId });
        setCorporation(company.corporationId);

        setCompany({
          id: company.id,
          companyId: company.companyId,
          name: company.name,
          shops: company.shops ?? [],
        });
        addCompanyId({ companyId, _companyId: company.companyId });
      }
    },
    [company?.id, companyData, refetch, setCorporation, addCompanyId],
  );

  useEffect(() => {
    if (!currentUser) {
      setCompany(null);
      logger.addContext({ key: "companyId", value: null });
    }
  }, [currentUser, setCompany]);

  return useMemo(() => [company, handleSetCompany] as const, [company, handleSetCompany]);
});
