import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert, Spin } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { CouponMenuDiscountInsertInput, CouponTypeEnum } from "types/graphql";

import { EditCouponFormValues } from "./EditCouponForm/useEditCouponForm";
import { EditCouponForm } from "./EditCouponForm";
import {
  useEditCouponDeleteCouponMenuDiscountMutation,
  useEditCouponGetCouponQuery,
  useEditCouponGetMenusQuery,
  useEditCouponUpdateCouponMutation,
  useEditCouponUpsertCouponMenuDiscountMutation,
} from "./queries";

export const EditCoupon = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const companyId = company?.id;
  const { id } = useParams<{ id: string }>();

  const {
    data: getMenusData,
    loading: loadingMenusData,
    error: getMenusError,
  } = useEditCouponGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });
  const menus = useMemo(() => getMenusData?.menu ?? [], [getMenusData]);

  const [updateCouponMutation, { loading: updateCouponLoading }] =
    useEditCouponUpdateCouponMutation();
  const [upsertCouponMenuDiscountMutation, { loading: upsertCouponMenuDiscountLoading }] =
    useEditCouponUpsertCouponMenuDiscountMutation();
  const [deleteCouponMenuDiscountMutation, { loading: deleteCouponMenuDiscountLoading }] =
    useEditCouponDeleteCouponMenuDiscountMutation();

  const {
    data,
    loading: loadingCouponData,
    refetch: refetchCoupon,
    error: getCouponError,
  } = useEditCouponGetCouponQuery(id ? { variables: { id } } : { skip: true });
  const coupon = data?.coupon_by_pk;

  const loading = Boolean(
    loadingMenusData ||
      loadingCouponData ||
      updateCouponLoading ||
      upsertCouponMenuDiscountLoading ||
      deleteCouponMenuDiscountLoading,
  );

  const shouldShowAlert = Boolean(getMenusError || getCouponError);

  const [{ loading: submitting }, onSubmit] = useAsyncFn(
    async ({ coupon: formValues }: { coupon: EditCouponFormValues }) => {
      try {
        if (!companyId || !id) return;
        const {
          name,
          title,
          availableDays,
          availableFrom,
          availableUntil,
          content,
          description,
          termsOfUse,
          couponType,
          ticket: ticketTypeCouponFormValues,
          menuDiscount: menuDiscountTypeCouponFormValues,
        } = formValues;
        const commonFormValues = {
          name,
          title,
          availableDays,
          availableFrom,
          availableUntil,
          content,
          description,
          termsOfUse,
          couponType,
        };

        const coupon = {
          ...commonFormValues,
          imageUrl:
            couponType === CouponTypeEnum.Ticket ? ticketTypeCouponFormValues?.imageUrl : null,
        };

        await updateCouponMutation({
          variables: { id, companyId, coupon },
        });

        if (couponType === CouponTypeEnum.MenuDiscount) {
          const menu = menus.find(
            (menu) => menu.menuId === menuDiscountTypeCouponFormValues?.menuId,
          );
          if (!menu) throw new Error("Menu not found");

          const couponMenuDiscount: CouponMenuDiscountInsertInput = {
            couponId: id,
            // NOTE: バリデーションでチェックできている
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
            discountedPrice: menuDiscountTypeCouponFormValues?.discountedPrice!,
            menuId: menu.id,
            _menuId: menu.menuId,
          };
          await upsertCouponMenuDiscountMutation({
            variables: { couponMenuDiscount },
          });
        }
        if (couponType === CouponTypeEnum.Ticket) {
          await deleteCouponMenuDiscountMutation({
            variables: { couponId: id, companyId },
          });
        }

        await refetchCoupon();

        message.success("更新しました");
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [
      companyId,
      id,
      menus,
      updateCouponMutation,
      refetchCoupon,
      upsertCouponMenuDiscountMutation,
      deleteCouponMenuDiscountMutation,
    ],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={coupon?.name ?? ""}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "クーポン" }],
      }}
    >
      <PageHeader title={coupon?.name ?? ""} onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <Spin spinning={loading}>
        {coupon && (
          <EditCouponForm
            coupon={coupon}
            onSubmit={onSubmit}
            loading={submitting}
            onClose={goBack}
            onFormValidationError={onFormValidationError}
            menus={menus}
          />
        )}
      </Spin>
    </DashboardLayout>
  );
};
