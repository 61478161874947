import { isApolloError } from "@apollo/client";

export type DomainException = {
  code: string;
  title: string;
  message: string;
};

/**
 * Get domain exception info from Apollo Error
 * @returns null if domain exception info was not found.
 */
export const getDomainException = (error: any): DomainException | null => {
  if (!isApolloError(error)) {
    return null;
  }
  const domainException = error?.graphQLErrors?.[0]?.extensions?.exception?.response;
  if (!domainException) {
    return null;
  }
  return {
    title: domainException.title ?? "",
    message: domainException.message ?? "",
    code: domainException.code ?? "",
  };
};
