import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Popconfirm, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import dayjs from "dayjs";
import { getText } from "models/customerSegment";
import { getNextDeliverAt } from "models/messageDelivery";

import { CopyIcon } from "components/ColorIcon/CopyIcon";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { MessageDelivery } from "../types";

import { MessageDeliveryTargetCount } from "./MessageDeliveryTargetCount";

type Props = {
  loading?: boolean;
  isSuspended: boolean;
  messageDeliveries: MessageDelivery[];
  onArchive: ({ id }: { id: string }) => void;
};

export const MessageDeliveryTable = memo<Props>(
  ({ loading, isSuspended, messageDeliveries, onArchive }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns: ColumnsType<MessageDelivery> = [
      {
        title: "配信管理名",
        dataIndex: "name",
        width: 300,
        render(text: string, { id }: MessageDelivery) {
          return canAccess("editMessageDelivery") ? (
            <Link to={`/messageDelivery/${id}/edit`}>
              <Button type="link">{text}</Button>
            </Link>
          ) : (
            text
          );
        },
      },
      {
        title: "配信対象",
        align: "left" as const,
        width: 500,
        render(_: string, { scope, customerSegment, customerList }: MessageDelivery) {
          if (scope === "broadcast") return "すべてのお客様";
          if (customerSegment) {
            return getText({ customerSegment });
          }
          if (customerList) return "顧客リスト";
        },
      },
      {
        title: "対象者数",
        align: "center" as const,
        width: 200,
        render: (_: string, { id, targetCount }: MessageDelivery) => (
          <MessageDeliveryTargetCount
            initialTargetCount={targetCount ?? null}
            messageDeliveryId={id}
          />
        ),
      },
      {
        title: "対象者数更新日時",
        align: "center" as const,
        width: 200,
        render(_: string, { targetCountUpdatedAt }: MessageDelivery) {
          return targetCountUpdatedAt
            ? dayjs(targetCountUpdatedAt).format("YYYY/MM/DD HH:mm")
            : "-";
        },
      },
      ...(!isSuspended
        ? [
            {
              title: "配信日時",
              align: "center" as const,
              width: 200,
              render(_: string, messageDelivery: MessageDelivery) {
                return getNextDeliverAt({ messageDelivery, now: dayjs() })?.format(
                  "YYYY/MM/DD HH:mm",
                );
              },
            },
          ]
        : []),
      {
        title: "毎日配信",
        align: "center" as const,
        width: 100,
        render(_: string, messageDelivery: MessageDelivery) {
          if (messageDelivery.repeatDeliveryTime === null) return null;

          return <Tag>有効</Tag>;
        },
      },
      ...(canAccess("addMessageDelivery")
        ? [
            {
              title: "",
              align: "center",
              fixed: "right",
              width: 60,
              render(_: string, { id }: MessageDelivery) {
                return (
                  <Tooltip title="コピーして新規作成">
                    <IconLink to={`/messageDelivery/add?id=${id}`}>
                      <CopyIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteMessageDelivery")
        ? [
            {
              title: "",
              align: "center" as const,
              fixed: "right",
              width: 60,
              render(_: string, { id }: MessageDelivery) {
                return (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>配信設定を削除しますか？</Paragraph>
                        <Paragraph>一度削除した配信設定を元に戻すことはできません。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    placement="topRight"
                    onConfirm={() => onArchive({ id })}
                  >
                    <DeleteIcon />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={messageDeliveries}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
