import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCouponGetCoupon = gql`
    query EditCouponGetCoupon($id: uuid!) {
  coupon_by_pk(id: $id) {
    id
    name
    title
    availableFrom
    availableUntil
    availableDays
    description
    termsOfUse
    content
    imageUrl
    couponType
    couponMenuDiscount {
      menuId: _menuId
      discountedPrice
    }
  }
}
    `;
export const EditCouponGetMenus = gql`
    query EditCouponGetMenus($companyId: uuid!) {
  menu(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    id
    menuId
    name
    price
    taxMethod
    taxRate
  }
}
    `;
export const EditCouponUpdateCoupon = gql`
    mutation EditCouponUpdateCoupon($id: uuid!, $companyId: uuid!, $coupon: coupon_set_input!) {
  update_coupon(
    _set: $coupon
    where: {id: {_eq: $id}, companyId: {_eq: $companyId}}
  ) {
    affected_rows
  }
}
    `;
export const EditCouponUpsertCouponMenuDiscount = gql`
    mutation EditCouponUpsertCouponMenuDiscount($couponMenuDiscount: couponMenuDiscount_insert_input!) {
  insert_couponMenuDiscount_one(
    object: $couponMenuDiscount
    on_conflict: {constraint: couponMenuDiscount_couponId_key, update_columns: [_menuId, discountedPrice]}
  ) {
    id
  }
}
    `;
export const EditCouponDeleteCouponMenuDiscount = gql`
    mutation EditCouponDeleteCouponMenuDiscount($couponId: uuid!, $companyId: uuid!) {
  delete_couponMenuDiscount(
    where: {couponId: {_eq: $couponId}, coupon: {companyId: {_eq: $companyId}}}
  ) {
    affected_rows
  }
}
    `;
export type EditCouponGetCouponQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditCouponGetCouponQuery = (
  { __typename?: 'query_root' }
  & { coupon_by_pk?: Types.Maybe<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name' | 'title' | 'availableFrom' | 'availableUntil' | 'availableDays' | 'description' | 'termsOfUse' | 'content' | 'imageUrl' | 'couponType'>
    & { couponMenuDiscount?: Types.Maybe<(
      { __typename?: 'couponMenuDiscount' }
      & Pick<Types.CouponMenuDiscount, 'discountedPrice'>
      & { menuId: Types.CouponMenuDiscount['_menuId'] }
    )> }
  )> }
);

export type EditCouponGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditCouponGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'name' | 'price' | 'taxMethod' | 'taxRate'>
  )> }
);

export type EditCouponUpdateCouponMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
  coupon: Types.CouponSetInput;
}>;


export type EditCouponUpdateCouponMutation = (
  { __typename?: 'mutation_root' }
  & { update_coupon?: Types.Maybe<(
    { __typename?: 'coupon_mutation_response' }
    & Pick<Types.CouponMutationResponse, 'affected_rows'>
  )> }
);

export type EditCouponUpsertCouponMenuDiscountMutationVariables = Types.Exact<{
  couponMenuDiscount: Types.CouponMenuDiscountInsertInput;
}>;


export type EditCouponUpsertCouponMenuDiscountMutation = (
  { __typename?: 'mutation_root' }
  & { insert_couponMenuDiscount_one?: Types.Maybe<(
    { __typename?: 'couponMenuDiscount' }
    & Pick<Types.CouponMenuDiscount, 'id'>
  )> }
);

export type EditCouponDeleteCouponMenuDiscountMutationVariables = Types.Exact<{
  couponId: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditCouponDeleteCouponMenuDiscountMutation = (
  { __typename?: 'mutation_root' }
  & { delete_couponMenuDiscount?: Types.Maybe<(
    { __typename?: 'couponMenuDiscount_mutation_response' }
    & Pick<Types.CouponMenuDiscountMutationResponse, 'affected_rows'>
  )> }
);


export const EditCouponGetCouponDocument = gql`
    query EditCouponGetCoupon($id: uuid!) {
  coupon_by_pk(id: $id) {
    id
    name
    title
    availableFrom
    availableUntil
    availableDays
    description
    termsOfUse
    content
    imageUrl
    couponType
    couponMenuDiscount {
      menuId: _menuId
      discountedPrice
    }
  }
}
    `;

/**
 * __useEditCouponGetCouponQuery__
 *
 * To run a query within a React component, call `useEditCouponGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCouponGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCouponGetCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditCouponGetCouponQuery(baseOptions: Apollo.QueryHookOptions<EditCouponGetCouponQuery, EditCouponGetCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCouponGetCouponQuery, EditCouponGetCouponQueryVariables>(EditCouponGetCouponDocument, options);
      }
export function useEditCouponGetCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCouponGetCouponQuery, EditCouponGetCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCouponGetCouponQuery, EditCouponGetCouponQueryVariables>(EditCouponGetCouponDocument, options);
        }
export type EditCouponGetCouponQueryHookResult = ReturnType<typeof useEditCouponGetCouponQuery>;
export type EditCouponGetCouponLazyQueryHookResult = ReturnType<typeof useEditCouponGetCouponLazyQuery>;
export type EditCouponGetCouponQueryResult = Apollo.QueryResult<EditCouponGetCouponQuery, EditCouponGetCouponQueryVariables>;
export const EditCouponGetMenusDocument = gql`
    query EditCouponGetMenus($companyId: uuid!) {
  menu(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    id
    menuId
    name
    price
    taxMethod
    taxRate
  }
}
    `;

/**
 * __useEditCouponGetMenusQuery__
 *
 * To run a query within a React component, call `useEditCouponGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCouponGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCouponGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditCouponGetMenusQuery(baseOptions: Apollo.QueryHookOptions<EditCouponGetMenusQuery, EditCouponGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCouponGetMenusQuery, EditCouponGetMenusQueryVariables>(EditCouponGetMenusDocument, options);
      }
export function useEditCouponGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCouponGetMenusQuery, EditCouponGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCouponGetMenusQuery, EditCouponGetMenusQueryVariables>(EditCouponGetMenusDocument, options);
        }
export type EditCouponGetMenusQueryHookResult = ReturnType<typeof useEditCouponGetMenusQuery>;
export type EditCouponGetMenusLazyQueryHookResult = ReturnType<typeof useEditCouponGetMenusLazyQuery>;
export type EditCouponGetMenusQueryResult = Apollo.QueryResult<EditCouponGetMenusQuery, EditCouponGetMenusQueryVariables>;
export const EditCouponUpdateCouponDocument = gql`
    mutation EditCouponUpdateCoupon($id: uuid!, $companyId: uuid!, $coupon: coupon_set_input!) {
  update_coupon(
    _set: $coupon
    where: {id: {_eq: $id}, companyId: {_eq: $companyId}}
  ) {
    affected_rows
  }
}
    `;
export type EditCouponUpdateCouponMutationFn = Apollo.MutationFunction<EditCouponUpdateCouponMutation, EditCouponUpdateCouponMutationVariables>;

/**
 * __useEditCouponUpdateCouponMutation__
 *
 * To run a mutation, you first call `useEditCouponUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCouponUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCouponUpdateCouponMutation, { data, loading, error }] = useEditCouponUpdateCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useEditCouponUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<EditCouponUpdateCouponMutation, EditCouponUpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCouponUpdateCouponMutation, EditCouponUpdateCouponMutationVariables>(EditCouponUpdateCouponDocument, options);
      }
export type EditCouponUpdateCouponMutationHookResult = ReturnType<typeof useEditCouponUpdateCouponMutation>;
export type EditCouponUpdateCouponMutationResult = Apollo.MutationResult<EditCouponUpdateCouponMutation>;
export type EditCouponUpdateCouponMutationOptions = Apollo.BaseMutationOptions<EditCouponUpdateCouponMutation, EditCouponUpdateCouponMutationVariables>;
export const EditCouponUpsertCouponMenuDiscountDocument = gql`
    mutation EditCouponUpsertCouponMenuDiscount($couponMenuDiscount: couponMenuDiscount_insert_input!) {
  insert_couponMenuDiscount_one(
    object: $couponMenuDiscount
    on_conflict: {constraint: couponMenuDiscount_couponId_key, update_columns: [_menuId, discountedPrice]}
  ) {
    id
  }
}
    `;
export type EditCouponUpsertCouponMenuDiscountMutationFn = Apollo.MutationFunction<EditCouponUpsertCouponMenuDiscountMutation, EditCouponUpsertCouponMenuDiscountMutationVariables>;

/**
 * __useEditCouponUpsertCouponMenuDiscountMutation__
 *
 * To run a mutation, you first call `useEditCouponUpsertCouponMenuDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCouponUpsertCouponMenuDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCouponUpsertCouponMenuDiscountMutation, { data, loading, error }] = useEditCouponUpsertCouponMenuDiscountMutation({
 *   variables: {
 *      couponMenuDiscount: // value for 'couponMenuDiscount'
 *   },
 * });
 */
export function useEditCouponUpsertCouponMenuDiscountMutation(baseOptions?: Apollo.MutationHookOptions<EditCouponUpsertCouponMenuDiscountMutation, EditCouponUpsertCouponMenuDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCouponUpsertCouponMenuDiscountMutation, EditCouponUpsertCouponMenuDiscountMutationVariables>(EditCouponUpsertCouponMenuDiscountDocument, options);
      }
export type EditCouponUpsertCouponMenuDiscountMutationHookResult = ReturnType<typeof useEditCouponUpsertCouponMenuDiscountMutation>;
export type EditCouponUpsertCouponMenuDiscountMutationResult = Apollo.MutationResult<EditCouponUpsertCouponMenuDiscountMutation>;
export type EditCouponUpsertCouponMenuDiscountMutationOptions = Apollo.BaseMutationOptions<EditCouponUpsertCouponMenuDiscountMutation, EditCouponUpsertCouponMenuDiscountMutationVariables>;
export const EditCouponDeleteCouponMenuDiscountDocument = gql`
    mutation EditCouponDeleteCouponMenuDiscount($couponId: uuid!, $companyId: uuid!) {
  delete_couponMenuDiscount(
    where: {couponId: {_eq: $couponId}, coupon: {companyId: {_eq: $companyId}}}
  ) {
    affected_rows
  }
}
    `;
export type EditCouponDeleteCouponMenuDiscountMutationFn = Apollo.MutationFunction<EditCouponDeleteCouponMenuDiscountMutation, EditCouponDeleteCouponMenuDiscountMutationVariables>;

/**
 * __useEditCouponDeleteCouponMenuDiscountMutation__
 *
 * To run a mutation, you first call `useEditCouponDeleteCouponMenuDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCouponDeleteCouponMenuDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCouponDeleteCouponMenuDiscountMutation, { data, loading, error }] = useEditCouponDeleteCouponMenuDiscountMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditCouponDeleteCouponMenuDiscountMutation(baseOptions?: Apollo.MutationHookOptions<EditCouponDeleteCouponMenuDiscountMutation, EditCouponDeleteCouponMenuDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCouponDeleteCouponMenuDiscountMutation, EditCouponDeleteCouponMenuDiscountMutationVariables>(EditCouponDeleteCouponMenuDiscountDocument, options);
      }
export type EditCouponDeleteCouponMenuDiscountMutationHookResult = ReturnType<typeof useEditCouponDeleteCouponMenuDiscountMutation>;
export type EditCouponDeleteCouponMenuDiscountMutationResult = Apollo.MutationResult<EditCouponDeleteCouponMenuDiscountMutation>;
export type EditCouponDeleteCouponMenuDiscountMutationOptions = Apollo.BaseMutationOptions<EditCouponDeleteCouponMenuDiscountMutation, EditCouponDeleteCouponMenuDiscountMutationVariables>;