import React, { memo, useEffect } from "react";
import { Button, Divider } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { TecAggregationIntegrationTypeEnum } from "types/graphql";
import { Maybe } from "types/Maybe";

import { TecAggregationConfig } from "../types";
import { useTecAggregationConfigForm } from "../useTecAggregationConfigForm";

import { EnabledField } from "./EnabledField";
import { FtpHostField } from "./FtpHostField";
import { FtpPasswordField } from "./FtpPasswordField";
import { FtpPortField } from "./FtpPortField";
import { FtpUserField } from "./FtpUserField";
import { IntegrationTypeField } from "./IntegrationTypeField";
import { SalesDataPutPathField } from "./SalesDataPutPathField";
import { TecAggregationCompanyCodeField } from "./TecAggregationCompanyCodeField";
import { TecAggregationPosNumberField } from "./TecAggregationPosNumberField";
import { TecAggregationShopCodeField } from "./TecAggregationShopCodeField";
import { useEditTecAggregationConfigFormPermission } from "./useEditTecAggregationConfigFormPermission";

type Props = {
  tecAggregationConfig?: Maybe<TecAggregationConfig>;
  onSubmit: Parameters<typeof useTecAggregationConfigForm>[0]["onSubmit"];
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  loading: boolean;
  integrationType: TecAggregationIntegrationTypeEnum;
};

export const EditTecAggregationConfigForm = memo<Props>(
  ({ onSubmit, onFormValidationError, loading, tecAggregationConfig, integrationType }) => {
    const { form, initialValues, submit } = useTecAggregationConfigForm({
      tecAggregationConfig,
      onFormValidationError,
      onSubmit,
    });
    const { canEditForm, canToggleStatus } = useEditTecAggregationConfigFormPermission();

    useEffect(() => {
      form.resetFields();
    }, [integrationType, form]);

    return (
      <>
        <IntegrationTypeField integrationType={integrationType} />
        <FormContent>
          <Form
            name="tecAggregationConfig"
            form={form}
            initialValues={initialValues}
            layout="vertical"
            disabled={!canEditForm && !canToggleStatus}
          >
            <EnabledField />
            <TecAggregationCompanyCodeField />
            <TecAggregationShopCodeField />
            <TecAggregationPosNumberField />
            <FtpHostField />
            <FtpPortField />
            <FtpUserField />
            <FtpPasswordField />
            <SalesDataPutPathField />
            <Divider />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button
            type="primary"
            onClick={submit}
            loading={loading}
            disabled={!canEditForm && !canToggleStatus}
          >
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
