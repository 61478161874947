import { useCallback, useEffect, useMemo } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import { Maybe, TecAggregationConfigInsertInput } from "types/graphql";

import { TecAggregationConfig } from "./types";

export type TecAggregationConfigFormValues = Pick<
  Partial<TecAggregationConfig>,
  | "enabled"
  | "companyCode"
  | "shopCode"
  | "posNumber"
  | "ftpHost"
  | "ftpPort"
  | "ftpUser"
  | "ftpPassword"
  | "salesDataPutPath"
>;

export const TecAggregationConfigFormItem = createFormItem<TecAggregationConfigFormValues>();

export const useTecAggregationConfigForm = ({
  tecAggregationConfig,
  onSubmit,
  onFormValidationError,
}: {
  tecAggregationConfig?: Maybe<TecAggregationConfig>;
  onSubmit: (args: Omit<TecAggregationConfigInsertInput, "shopId">) => Promise<void>;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<TecAggregationConfigFormValues>(
    () => ({
      enabled: tecAggregationConfig?.enabled ?? false,
      companyCode: tecAggregationConfig?.companyCode,
      shopCode: tecAggregationConfig?.shopCode,
      posNumber: tecAggregationConfig?.posNumber,
      ftpHost: tecAggregationConfig?.ftpHost,
      ftpPort: tecAggregationConfig?.ftpPort,
      ftpUser: tecAggregationConfig?.ftpUser,
      ftpPassword: tecAggregationConfig?.ftpPassword,
      salesDataPutPath: tecAggregationConfig?.salesDataPutPath,
    }),
    [tecAggregationConfig],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as TecAggregationConfigFormValues;

      await onSubmit({
        enabled: values.enabled,
        companyCode: values.companyCode ?? "",
        shopCode: values.shopCode ?? "",
        posNumber: values.posNumber ?? "",
        ftpHost: values.ftpHost ?? "",
        ftpPort: values.ftpPort ?? 0,
        ftpUser: values.ftpUser ?? "",
        ftpPassword: values.ftpPassword ?? "",
        salesDataPutPath: values.salesDataPutPath,
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError]);

  return { form, initialValues, submit };
};
