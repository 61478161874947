import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetCompany = gql`
    query GetCompany {
  company(where: {archivedAt: {_is_null: true}}) {
    id
    corporationId
    companyId
    name
    shops(where: {archivedAt: {_is_null: true}}) {
      shopId
      pikaichiConfig {
        pikaichiConfigId
        enabled
      }
      winboardConfig {
        winboardConfigId
        enabled
      }
      tecAggregationConfigs {
        id
        enabled
        integrationType
      }
    }
  }
}
    `;
export type GetCompanyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'corporationId' | 'companyId' | 'name'>
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'shopId'>
      & { pikaichiConfig?: Types.Maybe<(
        { __typename?: 'pikaichiConfig' }
        & Pick<Types.PikaichiConfig, 'pikaichiConfigId' | 'enabled'>
      )>, winboardConfig?: Types.Maybe<(
        { __typename?: 'winboardConfig' }
        & Pick<Types.WinboardConfig, 'winboardConfigId' | 'enabled'>
      )>, tecAggregationConfigs: Array<(
        { __typename?: 'tecAggregationConfig' }
        & Pick<Types.TecAggregationConfig, 'id' | 'enabled' | 'integrationType'>
      )> }
    )> }
  )> }
);


export const GetCompanyDocument = gql`
    query GetCompany {
  company(where: {archivedAt: {_is_null: true}}) {
    id
    corporationId
    companyId
    name
    shops(where: {archivedAt: {_is_null: true}}) {
      shopId
      pikaichiConfig {
        pikaichiConfigId
        enabled
      }
      winboardConfig {
        winboardConfigId
        enabled
      }
      tecAggregationConfigs {
        id
        enabled
        integrationType
      }
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;