import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { TablePrintingText } from "components/TablePrintingText";
import { useShop } from "hooks/useShop";

import { DishUpSlipGroupTableTable } from "./DishUpSlipGroupTableTable";
import {
  useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation,
  useDishUpSlipGroupTablePrintingGetTablesQuery,
  useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation,
} from "./queries";

export const DishUpSlipGroupTablePrinting = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data, loading, error, refetch } = useDishUpSlipGroupTablePrintingGetTablesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const tables = useMemo(() => data?.table ?? [], [data]);
  const dishUpSlipGroups = useMemo(() => data?.dishUpSlipGroup ?? [], [data]);
  const isTablePrintingEnabled = tables.flatMap((table) => table.dishUpSlipGroupTables).length > 0;

  const [deleteDishUpSlipGroupTable] =
    useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation();

  const [insertDishUpSlipGroupTable] =
    useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation();

  const handleToggle = useCallback(
    async ({ tableId, dishUpSlipGroupId }: { tableId: number; dishUpSlipGroupId: string }) => {
      try {
        const table = tables.find((table) => table.tableId === tableId);
        const dishUpSlipGroup = dishUpSlipGroups.find(
          (dishUpSlipGroup) => dishUpSlipGroup.dishUpSlipGroupId === dishUpSlipGroupId,
        );

        const checked = table?.dishUpSlipGroupTables.some(
          (dishUpSlipGroupTable) => dishUpSlipGroupTable.dishUpSlipGroupId === dishUpSlipGroupId,
        );

        if (!table) throw new Error("no table found");
        if (!dishUpSlipGroup) throw new Error("no dishUpSlipGroup found");
        if (!shopId) throw new Error("shopId is not available");

        if (checked) {
          await deleteDishUpSlipGroupTable({
            variables: { dishUpSlipGroupId, tableId: table.id },
          });
        } else {
          await insertDishUpSlipGroupTable({
            variables: {
              input: {
                dishUpSlipGroupId: dishUpSlipGroup.dishUpSlipGroupId,
                _dishUpSlipGroupId: dishUpSlipGroup.id,
                tableId: table.id,
                _tableId: table.tableId,
                shopId,
              },
            },
          });
        }

        message.success("設定を更新しました");
      } catch (e) {
        message.error("設定の更新に失敗しました");
      }
      await refetch();
    },
    [
      deleteDishUpSlipGroupTable,
      dishUpSlipGroups,
      insertDishUpSlipGroupTable,
      shopId,
      tables,
      refetch,
    ],
  );

  return (
    <DashboardLayout title="デシャップ伝票グループテーブル出し分け">
      <PageHeader
        title="デシャップ伝票グループテーブル出し分け"
        footer={
          <>
            <ShopSelector />
            <div>
              テーブル出し分け: <TablePrintingText isEnabled={isTablePrintingEnabled} />
              <br />
              テーブル出し分けが無効の場合、テーブルによらずメニューが紐づくすべてのプリンターから印刷されます。
            </div>
          </>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <DishUpSlipGroupTableTable
        tables={tables}
        loading={loading}
        dishUpSlipGroups={dishUpSlipGroups}
        onTogglePrintingConfig={handleToggle}
      />
    </DashboardLayout>
  );
};
