import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { insertWithRelation } from "util/type/insert-with-relation";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useGiftOption } from "hooks/useGiftOption";
import { useShop } from "hooks/useShop";
import { unwrap } from "libs/unwrap";
import { MenuInsertInput, MenuTypeEnum, TaxMethodEnum } from "types/graphql";

import { AddGiftForm } from "./AddGiftForm";
import {
  useAddGiftGetGiftMetaMaxPriorityQuery,
  useAddGiftInsertMenuMutation,
  useAddGiftInsertShopMenusMutation,
} from "./queries";

export const AddGift = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const [addMenuMutation, { loading: loadingAddMenu, error: addMenuError }] =
    useAddGiftInsertMenuMutation();
  const [addShopMenuMutation] = useAddGiftInsertShopMenusMutation();

  const { data: giftMetaMaxPriorityData } = useAddGiftGetGiftMetaMaxPriorityQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const giftMetaMaxPriority =
    giftMetaMaxPriorityData?.giftMeta_aggregate.aggregate?.max?.priority ?? 0;

  const { loading: loadingFindOrIssueGiftOption, findOrIssueGiftOption } = useGiftOption();

  const onSubmit = useCallback(
    async ({
      menu: { name, description, imageUrl, receiptDisplayName },
    }: {
      menu: Pick<MenuInsertInput, "name" | "description" | "imageUrl" | "receiptDisplayName">;
    }) => {
      try {
        if (!companyId) return;
        if (!shopId) return;

        const option = await findOrIssueGiftOption({ companyId, shopId });

        const menuId = uuidv4();
        const giftMetaId = uuidv4();
        const menu = insertWithRelation<["menu", "menuOptions"]>({
          id: menuId,
          name,
          description,
          imageUrl,
          receiptDisplayName,
          taxRate: 0,
          price: 0,
          // NOTE: 推しエールメニューは必ず非課税で作成する
          taxMethod: TaxMethodEnum.NonTaxable,
          companyId,
          menuOptions: { data: [{ menuId, optionId: option.id, _optionId: option.optionId }] },
          menuType: MenuTypeEnum.FaveYell,
          isTakeOut: false,
        });
        const { data } = await addMenuMutation({ variables: { menu } });

        await addShopMenuMutation({
          variables: {
            shopMenus: {
              shopIds: [shopId],
              menuId,
              isVisibleForCustomer: false,
              isVisibleForStaff: false,
            },
            giftMeta: {
              menuId: unwrap(data?.insert_menu_one?.id),
              _menuId: unwrap(data?.insert_menu_one?.serial),
              id: giftMetaId,
              priority: giftMetaMaxPriority,
            },
          },
        });
        message.success("作成しました");

        navigate("/gift", { replace: true });
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [
      companyId,
      shopId,
      findOrIssueGiftOption,
      giftMetaMaxPriority,
      addMenuMutation,
      addShopMenuMutation,
      navigate,
    ],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading = loadingAddMenu || loadingFindOrIssueGiftOption;
  const shouldShowAlert = addMenuError;

  return (
    <DashboardLayout
      title="推しエール対象メニューを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "推しエール対象メニュー一覧" }],
      }}
    >
      <PageHeader title="推しエール対象メニューを新規作成" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddGiftForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loading}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
