import React, { memo, useCallback, useMemo, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { Button, Collapse, Tooltip } from "antd";
import Title from "antd/lib/typography/Title";
import { uniq } from "lodash";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isNotNull } from "util/type/primitive";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { EditMenusBulkFormTable } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable";
import { EditMultipleMenusFields } from "pages/EditMenusBulk/EditMenusBulkForm/EditMultipleMenusFields";
import { EditMenusBulkGetMenusQuery } from "pages/EditMenusBulk/queries";
import { TaxMethodEnum, UpdateCategoryMenusBulkInput } from "types/graphql";

import { EditMenusBulkConfirmModal } from "../EditMenusBulkConfirmModal";
import { EditMenusPricesTargetSummary } from "../EditMenusBulkTargetSummary";
import { EditMenusPricesValidationErrorPanel } from "../EditMenusBulkValidationErrorPanel";
import { MenuFilter } from "../MenuFilter";
import { useCategoryFilter } from "../MenuFilter/useMenuFilter";

import {
  EditMenusBulkFormValues,
  EditMenusBulkMultipleEditValues,
  EditMenusBulkTargetValueCandidate,
  EditMenusBulkTargetValues,
  useEditMenusBulkForm,
} from "./useEditMenusBulkForm";

const initialTargetValue: EditMenusBulkFormValues = {
  price: undefined,
  taxRate: undefined,
  taxMethod: undefined,
  costPrice: undefined,
  costTaxRate: undefined,
  displayType: undefined,
  imageUrl: undefined,
};

const convertFormValuesToPartialTargetValue = (formValues: Partial<EditMenusBulkFormValues>) =>
  "displayType" in formValues
    ? { displayType: formValues.displayType !== undefined ? formValues.displayType : undefined }
    : "imageUrl" in formValues
    ? { imageUrl: formValues.imageUrl !== undefined ? formValues.imageUrl : undefined }
    : "price" in formValues
    ? { price: formValues.price !== undefined ? Number(formValues.price) : undefined }
    : "taxRate" in formValues
    ? { taxRate: formValues.taxRate !== undefined ? Number(formValues.taxRate) : undefined }
    : "taxMethod" in formValues
    ? { taxMethod: formValues.taxMethod !== undefined ? formValues.taxMethod : undefined }
    : "costTaxRate" in formValues
    ? {
        costTaxRate:
          formValues.costTaxRate !== undefined ? Number(formValues.costTaxRate) : undefined,
      }
    : { costPrice: formValues.costPrice !== undefined ? Number(formValues.costPrice) : undefined };

const filterTargetValues = ({
  categoryMenuId,
  changedValue,
  targetValues,
}: {
  categoryMenuId: number;
  changedValue: EditMenusBulkTargetValueCandidate;
  targetValues: EditMenusBulkTargetValues;
}): EditMenusBulkTargetValues => {
  const currentValue = targetValues[categoryMenuId];

  if (currentValue === undefined) {
    return {
      ...targetValues,
      [categoryMenuId]: {
        ...initialTargetValue,
        ...changedValue,
      },
    };
  } else {
    const updatedValue = {
      ...currentValue,
      ...changedValue,
    };

    if (
      Object.keys(updatedValue).every(
        (key) => updatedValue[key as keyof EditMenusBulkFormValues] === undefined,
      )
    ) {
      return Object.entries(targetValues)
        .filter(([key, _]) => key !== categoryMenuId.toString())
        .reduce<EditMenusBulkTargetValues>((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    }

    return {
      ...targetValues,
      [categoryMenuId]: updatedValue,
    };
  }
};

const StyledCollapse = styled(Collapse)`
  margin-bottom: 30px;
`;

const TitleContainer = styled.div`
  margin-top: 32px;
`;

type Props = {
  companyId: string | undefined;
  getMenusData: EditMenusBulkGetMenusQuery | undefined;
  loading: boolean;
  onSubmit: (input: UpdateCategoryMenusBulkInput) => void;
  onClose: () => void;
  defaultCostTaxMethod: TaxMethodEnum | undefined;
  onFormValidationError: (error: { formValidationError: ValidateErrorEntity }) => void;
};

export const EditMenusBulkForm = memo<Props>(
  ({
    companyId,
    getMenusData,
    loading,
    onSubmit,
    onClose,
    defaultCostTaxMethod,
    onFormValidationError,
  }) => {
    const {
      shops,
      categories,
      hasFilterConditions,
      filterConditions,
      updateFilterCondition,
      clearFilterConditions,
      filteredCategoryMenus,
      categoryMenus,
    } = useCategoryFilter({ getMenusData, companyId });

    const {
      form,
      initialValues,
      submit,
      targetValues,
      setTargetValues,
      categoryMenuIdToInitialValueMap,
    } = useEditMenusBulkForm({
      categoryMenus,
      filteredCategoryMenus,
      onSubmit,
    });
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const multiEditMode = selectedIds.length > 0;
    const [multiEditValues, setMultiEditValues] =
      useState<EditMenusBulkFormValues>(initialTargetValue);
    const [formValidationError, setFormValidationError] = useState<ValidateErrorEntity | null>(
      null,
    );

    const targetCategoryMenus = useMemo(
      () =>
        Object.entries(targetValues).map(([categoryMenuIdString, value]) => ({
          categoryMenuId: Number(categoryMenuIdString),
          ...value,
        })),
      [targetValues],
    );

    const handleSubmit = useCallback(async () => {
      setFormValidationError(null);
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
          setFormValidationError(e);
        }
      }
    }, [form, submit, onFormValidationError]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const handleChange = useCallback(
      (changedValue: EditMenusBulkTargetValues | EditMenusBulkMultipleEditValues) => {
        if ("multiEditValues" in changedValue) {
          setMultiEditValues((multiEditValues) => ({
            ...multiEditValues,
            ...convertFormValuesToPartialTargetValue(changedValue.multiEditValues),
          }));
        } else {
          Object.entries(changedValue).forEach(([categoryMenuIdString, value]) => {
            const categoryMenuId = Number(categoryMenuIdString);
            const changedValue = convertFormValuesToPartialTargetValue(value);
            setTargetValues((targetValues) =>
              filterTargetValues({ categoryMenuId, changedValue, targetValues }),
            );
          });
        }
      },
      [setTargetValues],
    );

    const handleMultiEditSubmit = useCallback(() => {
      // NOTE: 変更対象の値は必ず値が入るため undefined とのチェックで key ごと除外する
      // { price: 100, taxRate: undefined } → { price: 100 }
      const editedValues = Object.fromEntries(
        Object.entries(multiEditValues).filter(([_, value]) => value !== undefined),
      );

      setTargetValues((prev) =>
        Object.fromEntries(
          uniq(Object.keys(prev).map(Number).concat(selectedIds)).map((key) => [
            key,
            {
              ...(prev[key] ? prev[key] : initialTargetValue),
              ...(selectedIds.includes(key) ? editedValues : {}),
            },
          ]),
        ),
      );
      setSelectedIds([]);
      setMultiEditValues(initialTargetValue);
    }, [multiEditValues, selectedIds, setTargetValues]);

    const handleClearTarget = useCallback(
      (categoryMenuId: number) => {
        setTargetValues(
          Object.entries(targetValues)
            .filter(([key, _]) => key !== categoryMenuId.toString())
            .reduce<{
              [categoryMenuId: number]: EditMenusBulkFormValues;
            }>((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
        );
      },
      [setTargetValues, targetValues],
    );

    return (
      <>
        <FormContent>
          <MenuFilter
            shops={shops}
            categories={categories}
            hasFilterConditions={hasFilterConditions}
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            clearFilterConditions={clearFilterConditions}
          />
          {categoryMenus && (
            <Form name="editMenusPrices" form={form} onValuesChange={handleChange}>
              {multiEditMode ? (
                <>
                  <TitleContainer>
                    <Tooltip title="複数のメニュー設定を同時に設定できます。">
                      <Title level={5}>同時編集</Title>
                    </Tooltip>
                  </TitleContainer>
                  <EditMultipleMenusFields
                    defaultCostTaxMethod={defaultCostTaxMethod}
                    onSubmit={handleMultiEditSubmit}
                  />
                </>
              ) : null}
              <EditMenusBulkFormTable
                initialValues={initialValues}
                targetValues={targetValues}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                handleChange={handleChange}
              />
              <StyledCollapse
                defaultActiveKey={["2"]}
                items={[
                  {
                    key: "1",
                    header: "変更内容一覧",
                    children: (
                      <EditMenusPricesTargetSummary
                        targetCategoryMenus={targetCategoryMenus}
                        categoryMenuIdToInitialValueMap={categoryMenuIdToInitialValueMap}
                        onClear={(categoryMenuId: number) => handleClearTarget(categoryMenuId)}
                      />
                    ),
                  },
                  formValidationError !== null
                    ? {
                        key: "2",
                        header: "エラー内容一覧",
                        children: (
                          <EditMenusPricesValidationErrorPanel
                            formValidationError={formValidationError}
                          />
                        ),
                      }
                    : null,
                ].filter(isNotNull)}
              />
            </Form>
          )}
        </FormContent>
        <Spacer size={24} />

        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>

          <Link key="confirmEdit" to="/menu/edit/bulk/confirm" replace>
            <Button type="primary">確認</Button>
          </Link>

          <Routes>
            <Route
              path="confirm"
              element={
                <EditMenusBulkConfirmModal
                  targetValues={targetValues}
                  loading={loading}
                  onSubmit={handleSubmit}
                  categoryMenuIdToInitialValueMap={categoryMenuIdToInitialValueMap}
                />
              }
            />
          </Routes>
        </FormActions>
      </>
    );
  },
);
