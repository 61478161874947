import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Col, Divider, Row } from "antd";
import { Popconfirm } from "antd/lib";
import { taxRates } from "models/taxRate";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form, withFormDependencies } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { grey } from "constants/colors";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { CostTaxMethodField } from "pages/EditMenu/EditMenuForm//CostTaxMethodField";
import { CategoryField } from "pages/EditMenu/EditMenuForm/CategoryField";
import { CostPriceField } from "pages/EditMenu/EditMenuForm/CostPriceField";
import { CostTaxRateField } from "pages/EditMenu/EditMenuForm/CostTaxRateField";
import { DescriptionField } from "pages/EditMenu/EditMenuForm/DescriptionField";
import { DisplayTypeFields } from "pages/EditMenu/EditMenuForm/DisplayTypeFields";
import { FeaturedLabelTextField } from "pages/EditMenu/EditMenuForm/FeaturedLabelTextField";
import { MenuTypeField } from "pages/EditMenu/EditMenuForm/MenuTypeField";
import { NameField } from "pages/EditMenu/EditMenuForm/NameField";
import { OpenPriceField } from "pages/EditMenu/EditMenuForm/OpenPriceField";
import { OrderableTimeField } from "pages/EditMenu/EditMenuForm/OrderableTimeField";
import { ReceiptDisplayNameField } from "pages/EditMenu/EditMenuForm/ReceiptDisplayNameField";
import { ShopSideNameField } from "pages/EditMenu/EditMenuForm/ShopSideNameField";
import { ShouldMergeSlipOptionsField } from "pages/EditMenu/EditMenuForm/ShouldMergeSlipOptionsField";
import { TaxMethodField } from "pages/EditMenu/EditMenuForm/TaxMethodField";
import { TaxRateField } from "pages/EditMenu/EditMenuForm/TaxRateField";
import {
  DEFAULT_CATEGORY_ID,
  EditMenuFormItem,
  EditMenuFormValues,
  useEditMenuForm,
} from "pages/EditMenu/EditMenuForm/useEditMenuForm";
import { Category, Menu, OrderableTime } from "pages/EditMenu/types";
import { CategoryMenuInsertInput, MenuSetInput, TaxMethodEnum } from "types/graphql";

import { OrderLimitField } from "./OrderLimitFields/OrderLimitField";
import { OrderLimitForNumPeopleField } from "./OrderLimitFields/OrderLimitForNumPeopleField";
import { OrderLimitPerCustomerField } from "./OrderLimitFields/OrderLimitPerCustomerField";
import { OrderLimitPerTableUserField } from "./OrderLimitFields/OrderLimitPerTableUserField";
import { FirstOrderMenuDeletionConfirmationModal } from "./FirstOrderMenuDeletionConfirmationModal";
import { IsTakeOutField } from "./IsTakeOutField";
import { SinglePriceField } from "./SinglePriceField";

const FormWrapper = styled.div`
  background-color: ${grey[0]};
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledForm = styled(Form)`
  width: 640px;
  margin: 0 auto;
`;

const StyledFormSection = styled(FormSection)`
  padding: 0;
`;

const StyledDivider = styled(Divider)`
  margin: 32px 0;
`;

type Props = {
  menu: Menu;
  categories: Category[];
  categoryMaps: Record<number, string>;
  orderableTimes: OrderableTime[];
  defaultCostTaxMethod: TaxMethodEnum | undefined;
  onSubmit: ({
    menu,
    categoryMenus,
  }: {
    menu: MenuSetInput;
    categoryMenus: CategoryMenuInsertInput[];
  }) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onClose: () => void;
  loading: boolean;
  disabled: boolean;
};

export type PlanInfo = {
  id: string;
  planName: string;
}[];

export const EditMenuForm = memo<Props>(
  ({
    menu,
    categories,
    categoryMaps,
    orderableTimes,
    defaultCostTaxMethod,
    onClose,
    onSubmit,
    onFormValidationError,
    loading,
    disabled,
  }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
    const onOpenConfirmationModal = useCallback(() => {
      setConfirmationModalVisible(true);
    }, []);
    const onCloseConfirmationModal = useCallback(() => {
      setConfirmationModalVisible(false);
    }, []);

    const [removedPlansWithFirstOrder, setRemovedPlansWithFirstOrder] = useState<PlanInfo>([]);
    const {
      form,
      initialValues,
      submit,
      updateDisplayTypesWithCategoryIds,
      checkFirstOrderMenuAffect,
    } = useEditMenuForm(menu, categories, onSubmit, setRemovedPlansWithFirstOrder);

    const [disableTaxField, setDisableTaxField] = useState(
      menu.taxMethod === TaxMethodEnum.NonTaxable,
    );

    const [uploadImage, setUploadImage] = useState(initialValues.imageUrl);
    const [confirmTakeOutTaxRate, setConfirmTakeOutTaxRate] = useState(false);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({
          imageUrl: uploadImage,
        });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, uploadImage, form, onFormValidationError]);

    const handlePreSubmitChecks = useCallback(() => {
      const taxRate = form.getFieldValue("taxRate");
      const isTakeOut = form.getFieldValue("isTakeOut");

      if (!isTakeOut && taxRate === taxRates["0.08"]) {
        setConfirmTakeOutTaxRate(true);
        return;
      }

      handleSubmit();
    }, [form, handleSubmit]);

    const handleCloseConfirm = useCallback(() => setConfirmTakeOutTaxRate(false), []);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const onValuesChange = useCallback(
      (values: Partial<EditMenuFormValues>) => {
        if (values?.taxMethod) {
          setDisableTaxField(values.taxMethod === TaxMethodEnum.NonTaxable);
          return;
        }

        updateDisplayTypesWithCategoryIds(values);
        checkFirstOrderMenuAffect(values);
      },
      [checkFirstOrderMenuAffect, updateDisplayTypesWithCategoryIds],
    );

    return (
      <>
        <FormWrapper>
          <StyledForm
            name="menu"
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onValuesChange={onValuesChange}
          >
            <StyledFormSection title="商品画像">
              <ImageField
                image={uploadImage}
                setUploadImage={setUploadImage}
                disabled={disabled}
                uploadImageApiKey="menu"
                formName="EditMenuForm"
                isMovieAccepted
              />
            </StyledFormSection>

            <StyledDivider />

            <StyledFormSection title="メニュー情報">
              <Row gutter={24}>
                <Col span={12}>
                  <NameField disabled={disabled} />
                </Col>
                <Col span={12}>
                  <ReceiptDisplayNameField disabled={disabled} />
                </Col>
                <Col span={12}>
                  <ShopSideNameField disabled={disabled} />
                </Col>
              </Row>

              <CategoryField
                data-cy="menu_category_select"
                categories={categories}
                disabled={disabled}
              />
              <OrderableTimeField
                data-cy="menu_orderableTime_select"
                orderableTimes={orderableTimes}
                disabled={disabled}
              />
              <MenuTypeField disabled={disabled} />
              <DescriptionField disabled={disabled} />

              <Row gutter={24}>
                <Col span={12}>
                  <FeaturedLabelTextField disabled={disabled} />
                </Col>
              </Row>

              <DisplayTypeFields
                categoryMaps={categoryMaps}
                defaultCategoryId={DEFAULT_CATEGORY_ID}
                disabled={disabled}
              />
            </StyledFormSection>

            <StyledDivider />

            <StyledFormSection title="販売価格と原価">
              <IsTakeOutField />
              <OpenPriceField disabled={disabled} />

              <Row gutter={24}>
                <Col span={6}>
                  <TaxMethodField disabled={disabled} />
                </Col>
                <Col span={6}>
                  <TaxRateField disabled={disabled || disableTaxField} />
                </Col>
              </Row>

              <EditMenuFormItem.NonProperty
                shouldUpdate={withFormDependencies(({ openPrice }) => [openPrice])}
                noStyle
              >
                {({ getFieldValue }) => (
                  <SinglePriceField openPrice={getFieldValue("openPrice")} disabled={disabled} />
                )}
              </EditMenuFormItem.NonProperty>

              <Row gutter={24}>
                <Col span={6}>
                  <CostTaxMethodField value={defaultCostTaxMethod} />
                </Col>
                <Col span={6}>
                  <CostTaxRateField disabled={disabled} />
                </Col>
                <Col span={6}>
                  <CostPriceField disabled={disabled} />
                </Col>
              </Row>
            </StyledFormSection>

            {isFeatureEnabled("showShouldMergeSlipOptionsFields") && (
              <>
                <StyledDivider />
                <StyledFormSection title="キッチン伝票">
                  <ShouldMergeSlipOptionsField disabled={disabled} />
                </StyledFormSection>
              </>
            )}

            <StyledDivider />
            <StyledFormSection title="注文上限">
              <OrderLimitPerTableUserField disabled={disabled} />
              <OrderLimitPerCustomerField disabled={disabled} />
              <OrderLimitField disabled={disabled} />
              <OrderLimitForNumPeopleField disabled={disabled} />
            </StyledFormSection>
          </StyledForm>
        </FormWrapper>

        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Popconfirm
            title="税率が8％ですがテイクアウトメニューに設定しないでよろしいでしょうか。"
            onConfirm={handleSubmit}
            onCancel={handleCloseConfirm}
            open={confirmTakeOutTaxRate}
            okText="はい"
            cancelText="キャンセル"
          >
            <Button
              type="primary"
              onClick={
                removedPlansWithFirstOrder.length > 0
                  ? onOpenConfirmationModal
                  : handlePreSubmitChecks
              }
              loading={loading}
              disabled={disabled}
            >
              更新
            </Button>
          </Popconfirm>
        </FormActions>
        <FirstOrderMenuDeletionConfirmationModal
          open={confirmationModalVisible}
          onCancel={onCloseConfirmationModal}
          onSubmit={handleSubmit}
          menu={menu}
          removedPlansWithFirstOrder={removedPlansWithFirstOrder}
        />
      </>
    );
  },
);
