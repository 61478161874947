import React, { memo, useMemo } from "react";
import { Select } from "antd";
import { getTecAggregationIntegrationTypeName } from "models/tecAggregationIntegrationType";

import { TecAggregationConfig, TecAggregationIntegrationTypeEnum } from "types/graphql";

import { TecAggregationUploadFormItem } from "../useTecAggregationUploadForm";

type Props = {
  tecAggregationConfigs: Pick<TecAggregationConfig, "id" | "enabled" | "integrationType">[];
};

export const IntegrationTypeField = memo<Props>(({ tecAggregationConfigs }) => {
  const integrationTypeOptions = useMemo(
    () =>
      tecAggregationConfigs
        ?.filter((config) => config.enabled)
        .map((config) => ({
          label: getTecAggregationIntegrationTypeName(config.integrationType),
          value: config.integrationType,
        })),
    [tecAggregationConfigs],
  );

  return (
    <TecAggregationUploadFormItem
      label="転送先BIツール"
      name="integrationType"
      rules={[{ required: true }]}
      wrapperCol={{ span: 8 }}
    >
      <Select<TecAggregationIntegrationTypeEnum>
        showSearch
        placeholder="転送先のBIツールを選択"
        options={integrationTypeOptions}
      />
    </TecAggregationUploadFormItem>
  );
});
