import React, { memo, useMemo } from "react";
import { LineChartOutlined } from "@ant-design/icons";
import { integrationTypes } from "models/tecAggregationIntegrationType";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import { CoineyMenu } from "components/Layout/DashboardLayout/NavigationDrawer/StatsMenu/CoineyMenu";
import { PikaichiMenu } from "components/Layout/DashboardLayout/NavigationDrawer/StatsMenu/PikaichiMenu";
import { TecAggregationMenu } from "components/Layout/DashboardLayout/NavigationDrawer/StatsMenu/TecAggregationMenu";
import { WinboardMenu } from "components/Layout/DashboardLayout/NavigationDrawer/StatsMenu/WinboardMenu";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company, Shop } from "components/Layout/DashboardLayout/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardFeatureEnum } from "types/graphql";

import { FoodingJournalMenu } from "./FoodingJournalMenu";

type Props = {
  shop?: Shop;
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const StatsMenu = memo<Props>(({ company, ...props }) => {
  const canShowCoineyMenuItem = company?.shops.some((s) => s.useKiosk) ?? false;

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const canShowWinboardMenuItem =
    isFeatureEnabled("showWinboardConfig") ||
    (company?.shops.some((s) => s.winboardConfig) ?? false);
  const canShowPikaichiMenuItem =
    isFeatureEnabled("showPikaichiConfig") ||
    (company?.shops.some((s) => s.pikaichiConfig) ?? false);
  const canShowTecAggregationMenuItem =
    (isFeatureEnabled("showTecAggregationConfig") ||
      company?.shops.some((s) => s.tecAggregationConfigs.length > 0)) ??
    false;
  const canShowFoodingJournalMenuItem =
    (isFeatureEnabled("showFoodingJournalConfig") ||
      company?.shops.some((s) => s.foodingJournalConfig)) ??
    false;
  const tecAggregationIntegrationTypes = useMemo(
    () =>
      Object.keys(integrationTypes).filter((key) =>
        company?.shops?.some(({ tecAggregationConfigs }) =>
          tecAggregationConfigs?.some((config) => config?.integrationType === key),
        ),
      ),
    [company],
  );

  return (
    <SubMenu
      key="dashboard"
      icon={<LineChartOutlined />}
      title="分析"
      features={[DashboardFeatureEnum.Aggregation]}
      {...props}
    >
      <MenuItem route="currentSalesList" to="/currentSalesList" text="店舗別売上速報一覧" />
      <MenuItem route="salesAnalytics" to="/sales-analytics" text="売上分析（β版）" />
      <MenuItem
        route="companySalesAnalytics"
        to="/company-sales-analytics"
        text="店舗別売上一覧（β版）"
      />
      <MenuItem route="sales" to="/sale" text="売上" />
      <MenuItem
        route="salesByDayofweekAndHourAnalytics"
        to="/sales-by-dayofweek-and-hour-analytics"
        text="曜日・時間別分析"
      />
      <MenuItem route="menuOrderItemAnalytics" to="/product-analytics" text="商品分析" />
      <MenuItem route="faveYell" to="/fave-yell-analytics" text="推しエール分析" />
      <CoineyMenu canShow={canShowCoineyMenuItem} />
      <MenuItem
        route="exportDailyAggregatedData"
        to="/aggregatedData/daily/export"
        text="CSV ダウンロード"
      />
      <PikaichiMenu company={company} canShow={canShowPikaichiMenuItem} />
      <WinboardMenu company={company} canShow={canShowWinboardMenuItem} />
      <TecAggregationMenu
        canShow={canShowTecAggregationMenuItem}
        integrationTypes={tecAggregationIntegrationTypes}
      />
      <FoodingJournalMenu canShow={canShowFoodingJournalMenuItem} />
      <MenuItem route="analyticsSettings" to="/analytics-settings" text="分析設定" />
    </SubMenu>
  );
});
