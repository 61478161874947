import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { List, Popconfirm, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getTypeName } from "models/displayType";
import { getTaxMethodName } from "models/taxMethod";

import { CopyIcon } from "components/ColorIcon/CopyIcon";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { grey } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { useViewport } from "hooks/useViewport";
import { Menu, Shop } from "pages/Menus/types";
import { MenuTypeEnum } from "types/graphql";

const { Paragraph } = Typography;

export type MenuTableDisplayType = "compact" | "detail";

const NoCategoriesText = styled.span`
  color: ${grey[5]};
`;

const DetailCategoryNameColumn = styled.div`
  width: ${150 - 15}px;
`;

const DetailDisplayTypeColumn = styled.div`
  width: 100px;
`;

const DetailPriceColumn = styled.div`
  width: ${150 - 15}px;
`;

type Props = {
  loading?: boolean;
  menus: Menu[];
  shopIdToShopMap: Map<string, Shop>;
  displayType: MenuTableDisplayType;
  onDelete: ({ menuId, serial }: { menuId: string; serial: number }) => void;
};

export const MenuTable = memo<Props>(
  ({ loading, menus, shopIdToShopMap, displayType, onDelete }) => {
    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const [pagination, setPagination] = usePagination();

    const { isDesktop } = useViewport();

    const compactColumns: ColumnsType<Menu> = [
      {
        title: "画像",
        align: "center",
        width: 100,
        render(_: string, { imageUrl }: Menu) {
          return <Thumbnail url={imageUrl} width={64} height={64} />;
        },
      },
      {
        title: "商品名",
        width: 300,
        render(_: unknown, { serial, name }: Menu) {
          return isFeatureEnabled("editMenu") ? (
            <Tooltip title="メニューを編集">
              <Link to={`/menu/${serial}/edit`}>{name}</Link>
            </Tooltip>
          ) : (
            <Link to={`/menu/${serial}/edit`}>{name}</Link>
          );
        },
      },
      {
        title: "カテゴリ名",
        width: 200,
        render(_: unknown, { categoryMenus }: Menu) {
          return categoryMenus.length === 0 ? (
            <NoCategoriesText>カテゴリなし</NoCategoriesText>
          ) : (
            <List
              dataSource={categoryMenus}
              renderItem={({ category }) => (
                <DetailCategoryNameColumn>
                  {canAccess("editCategory") ? (
                    <Link to={`/category/${category.categoryId}/edit`}>{category.name}</Link>
                  ) : (
                    category.name
                  )}
                </DetailCategoryNameColumn>
              )}
            />
          );
        },
      },
      {
        title: "画像の表示サイズ",
        width: 150,
        render(_: unknown, { categoryMenus }: Menu) {
          return (
            categoryMenus.length !== 0 && (
              <List
                dataSource={categoryMenus}
                renderItem={({ displayType }) => (
                  <DetailDisplayTypeColumn>{getTypeName(displayType)}</DetailDisplayTypeColumn>
                )}
              />
            )
          );
        },
      },
      {
        title: "価格",
        width: 150,
        render(_: unknown, { price, openPrice, taxMethod }: Menu) {
          return (
            <DetailPriceColumn>
              {openPrice ? "フリー" : `${price}円(${getTaxMethodName(taxMethod)})`}
            </DetailPriceColumn>
          );
        },
      },
      {
        title: "オプション",
        width: 150,
        render(_: string, { menuOptions }: Menu) {
          return menuOptions.map(({ option }) => (
            <Link to={`/option/${option.optionId}/edit`} key={option.optionId}>
              <Tag color="processing">{option.name}</Tag>
            </Link>
          ));
        },
      },
      {
        title: "アプリ表示時間",
        align: "center",
        width: 200,
        render(_: unknown, { orderableTime }: Menu) {
          if (!orderableTime) return "未設定";
          return orderableTime.name;
        },
      },
      ...(isFeatureEnabled("addMenu")
        ? [
            {
              title: "",
              align: "center",
              fixed: "right",
              width: 60,
              render(_: string, { serial }: Menu) {
                return (
                  <Tooltip title="コピーして新規作成">
                    <IconLink to={`/menu/add?menuId=${serial}`}>
                      <CopyIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteMenu")
        ? [
            {
              title: "",
              align: "center",
              fixed: "right",
              width: 60,
              render(
                _: string,
                { id, serial, compulsoryAppetizerConfigs, totalDealingShopCount, shopMenus }: Menu,
              ) {
                if (compulsoryAppetizerConfigs.length > 0) {
                  return (
                    <Tooltip title="お通し設定を解除してから削除してください">
                      <DeleteIcon disabled={true} />
                    </Tooltip>
                  );
                }

                const disabled = totalDealingShopCount !== shopMenus.length;

                return (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>メニューを削除しますか？</Paragraph>
                        <Paragraph>一度削除したメニューを元に戻すことはできません。</Paragraph>
                        <Paragraph>売上の記録は維持されます。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    onConfirm={() => {
                      onDelete({ menuId: id, serial });
                    }}
                    disabled={disabled}
                  >
                    <DeleteIcon disabled={disabled} />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ];

    const detailColumns: ColumnsType<Menu> = [
      {
        title: "画像",
        width: 100,
        align: "center",
        fixed: isDesktop ? "left" : undefined,
        render(_: string, { imageUrl }: Menu) {
          return <Thumbnail url={imageUrl} width={64} height={64} />;
        },
      },
      {
        title: "商品名",
        fixed: isDesktop ? "left" : undefined,
        width: 200,
        render(_: unknown, { serial, name }: Menu) {
          return isFeatureEnabled("editMenu") ? (
            <Tooltip title="メニューを編集">
              <Link to={`/menu/${serial}/edit`}>{name}</Link>
            </Tooltip>
          ) : (
            <Link to={`/menu/${serial}/edit`}>{name}</Link>
          );
        },
      },
      {
        title: "取扱店舗",
        width: 150,
        render(_: string, { serial, shopMenus }: Menu) {
          return shopMenus.map(({ shopId }) => {
            const shop = shopIdToShopMap.get(shopId);
            if (!shop) return null;
            return (
              <Link to={`/menu/${serial}/dealer/edit`} key={shopId}>
                <Tag color="processing">{shop.name}</Tag>
              </Link>
            );
          });
        },
      },
      {
        title: "カテゴリ名",
        width: 150,
        render(_: unknown, { categoryMenus }: Menu) {
          return categoryMenus.length === 0 ? (
            <NoCategoriesText>カテゴリなし</NoCategoriesText>
          ) : (
            <List
              dataSource={categoryMenus}
              renderItem={({ category }) => (
                <DetailCategoryNameColumn>
                  {canAccess("editCategory") ? (
                    <Link to={`/category/${category.categoryId}/edit`}>{category.name}</Link>
                  ) : (
                    category.name
                  )}
                </DetailCategoryNameColumn>
              )}
            />
          );
        },
      },
      {
        title: "画像の表示サイズ",
        width: 150,
        render(_: unknown, { categoryMenus }: Menu) {
          return (
            categoryMenus.length !== 0 && (
              <List
                dataSource={categoryMenus}
                renderItem={({ displayType }) => (
                  <DetailDisplayTypeColumn>{getTypeName(displayType)}</DetailDisplayTypeColumn>
                )}
              />
            )
          );
        },
      },
      {
        title: "価格",
        dataIndex: "price",
        width: 150,
        render(_: unknown, { price, openPrice, taxMethod }: Menu) {
          return (
            <DetailPriceColumn>
              {openPrice ? "フリー" : `${price}円(${getTaxMethodName(taxMethod)})`}
            </DetailPriceColumn>
          );
        },
      },
      {
        title: "税率",
        width: 70,
        render(_: string, { taxRate }: Menu) {
          return `${Math.floor(taxRate * 100)}%`;
        },
      },
      {
        title: "伝票表示名",
        dataIndex: "receiptDisplayName",
        width: 150,
      },
      {
        title: "ハンディ表示名",
        dataIndex: "shopSideName",
        width: 150,
      },
      {
        title: "オプション",
        width: 150,
        render(_: string, { menuOptions }: Menu) {
          return menuOptions.map(({ option }) => (
            <Link to={`/option/${option.optionId}/edit`} key={option.optionId}>
              <Tag color="processing">{option.name}</Tag>
            </Link>
          ));
        },
      },
      {
        title: "原価",
        dataIndex: "costPrice",
        align: "right",
        width: 150,
        render(_: string, { costPrice }: Menu) {
          return costPrice ? `${costPrice}円` : "";
        },
      },
      {
        title: "原価税率",
        width: 70,
        render(_: string, { costTaxRate }: Menu) {
          return costTaxRate ? `${Math.floor(costTaxRate * 100)}%` : "";
        },
      },
      { title: "説明", width: 150, dataIndex: "description" },
      {
        title: "特集ラベル",
        align: "center",
        width: 150,
        render(_: unknown, { featuredLabelText }: Menu) {
          return featuredLabelText;
        },
      },
      {
        title: "アプリ表示時間",
        align: "center",
        width: 100,
        render(_: unknown, { orderableTime }: Menu) {
          if (!orderableTime) return "未設定";
          return orderableTime.name;
        },
      },
      {
        title: "注文上限数",
        align: "center",
        width: 120,
        render(_: unknown, { orderMaxNum }: Menu) {
          if (orderMaxNum === null) return "なし";
          return orderMaxNum;
        },
      },
      {
        title: "お一人様ごとの注文制限",
        align: "center",
        width: 120,
        render(_: unknown, { unitMaxOrderNumForNumPeople }: Menu) {
          if (!unitMaxOrderNumForNumPeople) return "なし";
          return unitMaxOrderNumForNumPeople;
        },
      },
      {
        title: "メニュータイプ",
        align: "center",
        width: 120,
        render(_: unknown, { menuType }: Menu) {
          switch (menuType) {
            case MenuTypeEnum.Drink:
              return "ドリンク";
            case MenuTypeEnum.Food:
              return "フード";
            case MenuTypeEnum.Other:
              return "その他";
            default:
              return "";
          }
        },
      },
      ...(isFeatureEnabled("addMenu")
        ? [
            {
              title: "",
              align: "center",
              fixed: "right",
              width: 60,
              render(_: string, { serial }: Menu) {
                return (
                  <Tooltip title="コピーして新規作成">
                    <IconLink to={`/menu/add?menuId=${serial}`}>
                      <CopyIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteMenu")
        ? [
            {
              title: "",
              align: "center",
              fixed: "right",
              width: 60,
              render(
                _: string,
                { id, serial, compulsoryAppetizerConfigs, totalDealingShopCount, shopMenus }: Menu,
              ) {
                if (compulsoryAppetizerConfigs.length > 0) {
                  return (
                    <Tooltip title="お通し設定を解除してから削除してください">
                      <DeleteIcon disabled={true} />
                    </Tooltip>
                  );
                }

                const disabled = totalDealingShopCount !== shopMenus.length;

                return (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>メニューを削除しますか？</Paragraph>
                        <Paragraph>一度削除したメニューを元に戻すことはできません。</Paragraph>
                        <Paragraph>売上の記録は維持されます。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    onConfirm={() => {
                      onDelete({ menuId: id, serial });
                    }}
                    disabled={disabled}
                  >
                    <DeleteIcon disabled={disabled} />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ];

    return (
      <Table
        rowKey="menuId"
        columns={displayType === "detail" ? detailColumns : compactColumns}
        dataSource={menus}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
