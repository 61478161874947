import { useMemo } from "react";
import dayjs from "dayjs";
import { groupBy, range } from "lodash";
import { isNotNull } from "util/type/primitive";

import { dayjsToDateString } from "libs/DateString";
import { SalesByDayOfWeekAndHourAnalyticsOutputItem } from "types/graphql";

import { useSalesByDayofweekAndHourAnalyticsQuery } from "./queries";
import { NormalizedSalesAnalyticsRows } from "./types";

const normalizedTaxSettingsAnalytics = ({
  rows,
  showTaxIncluded,
}: {
  rows: SalesByDayOfWeekAndHourAnalyticsOutputItem[];
  showTaxIncluded: boolean;
}): NormalizedSalesAnalyticsRows => {
  const rowsWithAmountByTaxSetting = rows.map((row) => ({
    dayofweek: row.dayofweek,
    hour: row.hour,
    shopId: row.shopId,
    shopName: row.shopName,
    numPeople: row.numPeople,
    totalAmount: showTaxIncluded ? row.totalTaxIncludedAmount : row.totalTaxExcludedAmount,
    salesPerCustomer:
      row.numPeople > 0
        ? showTaxIncluded
          ? Math.round(row.totalTaxIncludedAmount / row.numPeople)
          : Math.round(row.totalTaxExcludedAmount / row.numPeople)
        : 0,
  }));

  const dayofweek = ["日", "月", "火", "水", "木", "金", "土"];
  const hours = range(0, 24);

  const rowsWithEmptyDate = Object.entries(
    groupBy(rowsWithAmountByTaxSetting, ({ shopId }) => shopId),
  )
    .map(([shopId, shopRows]) =>
      dayofweek.map((day) => {
        const referencesDayofweek = shopRows.filter((shopRow) => shopRow.dayofweek === day);

        if (referencesDayofweek.length > 0) {
          return referencesDayofweek;
        }

        const shopName = shopRows?.[0]?.shopName;
        if (!shopName) return null;

        return hours.map((hour) => ({
          dayofweek: day,
          hour: `${hour}`,
          shopId,
          shopName,
          isEmptyRow: true,
          numPeople: null,
          totalAmount: null,
          salesPerCustomer: null,
        }));
      }),
    )
    .flat(2)
    .filter(isNotNull);

  const rowsWithEmptyHour = Object.entries(groupBy(rowsWithEmptyDate, ({ dayofweek }) => dayofweek))
    .map(([dayofweek, shopRows]) =>
      hours.map((hour) => {
        const referenceHour = shopRows.find((shopRow) => shopRow.hour === `${hour}`);

        if (referenceHour) {
          return referenceHour;
        }

        const shopId = shopRows?.[0]?.shopId;
        const shopName = shopRows?.[0]?.shopName;
        if (!shopName) return null;

        const emptyRow = {
          dayofweek,
          shopId,
          shopName,
          hour: `${hour}`,
          isEmptyRow: true,
          numPeople: null,
          totalAmount: null,
          salesPerCustomer: null,
        };

        return emptyRow;
      }),
    )
    .flat()
    .filter(isNotNull);

  // Remove hours with no sales data
  const rowsFiltered = Object.entries(groupBy(rowsWithEmptyHour, ({ hour }) => hour))
    .filter(([hour, hourRows]) => hourRows.some((row) => Boolean(row.totalAmount)))
    .flatMap(([hour, hourRows]) => hourRows);

  const sortedRows = rowsFiltered.sort((a, b) => {
    const hourA = Number(a.hour);
    const hourB = Number(b.hour);

    if (hourA >= 6 && hourB < 6) return -1;
    if (hourA < 6 && hourB >= 6) return 1;

    return Number(a.hour) < Number(b.hour) ? -1 : 1;
  });

  return groupBy(sortedRows, ({ dayofweek }) => dayofweek);
};

export const useSalesByDayofweekAndHourAnalytics = ({
  shopId,
  startDate,
  endDate,
  showTaxIncluded,
}: {
  shopId: string | null;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  showTaxIncluded: boolean;
}) => {
  const { data, loading } = useSalesByDayofweekAndHourAnalyticsQuery(
    shopId && startDate && endDate
      ? {
          variables: {
            input: {
              shopId,
              startAt: dayjsToDateString(startDate),
              endAt: dayjsToDateString(endDate),
            },
          },
        }
      : { skip: true },
  );

  const rows = useMemo(() => data?.salesByDayOfWeekAndHourAnalytics.rows ?? [], [data]);

  const normalizedRows = useMemo(
    () =>
      normalizedTaxSettingsAnalytics({
        rows,
        showTaxIncluded,
      }),
    [rows, showTaxIncluded],
  );

  return { normalizedRows, isLoading: loading };
};
