import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddMenuGetCategoriesAndMenus = gql`
    query AddMenuGetCategoriesAndMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
    categoryMenus {
      categoryId: _categoryId
      priority
    }
  }
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    menuId
    description
    featuredLabelText
    imageUrl
    name
    orderMaxNum
    orderMaxNumPerTableUser
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
    isTakeOut
    unitMaxOrderNumForNumPeople
    unitMaxOrderNumPerCustomer
    openPrice
    orderableTimeId
    shouldMergeSlipOptions
    costPrice
    costTaxRate
    menuType
    price
    categoryMenus(
      where: {category: {_not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      priority
      displayType
      category {
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
    ) {
      optionId
      _optionId
      option {
        id
      }
    }
    translations {
      columnName
      locale
      value
    }
  }
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {optionId: asc}
  ) {
    optionId
    name
    menuOptions(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: {_menuId: asc}
    ) {
      menuId: _menuId
      menu {
        name
      }
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      name
    }
  }
}
    `;
export const AddMenuGetOrderableTimes = gql`
    query AddMenuGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const AddMenuGetCompany = gql`
    query AddMenuGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    companyId
    defaultMenuTaxMethod
    defaultCostTaxMethod
  }
}
    `;
export const AddMenuInsertMenu = gql`
    mutation AddMenuInsertMenu($menu: CreateMenuInput!) {
  createMenu(input: $menu) {
    menuId
  }
}
    `;
export type AddMenuGetCategoriesAndMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMenuGetCategoriesAndMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'id' | 'categoryId' | 'name' | 'shopSideName'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'priority'>
      & { categoryId: Types.CategoryMenu['_categoryId'] }
    )> }
  )>, menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'menuId' | 'description' | 'featuredLabelText' | 'imageUrl' | 'name' | 'orderMaxNum' | 'orderMaxNumPerTableUser' | 'receiptDisplayName' | 'shopSideName' | 'taxMethod' | 'taxRate' | 'isTakeOut' | 'unitMaxOrderNumForNumPeople' | 'unitMaxOrderNumPerCustomer' | 'openPrice' | 'orderableTimeId' | 'shouldMergeSlipOptions' | 'costPrice' | 'costTaxRate' | 'menuType' | 'price'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'priority' | 'displayType'>
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & Pick<Types.MenuOption, 'optionId' | '_optionId'>
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'id'>
      ) }
    )>, translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )> }
  )>, option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'optionId' | 'name'>
    & { menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { menuId: Types.MenuOption['_menuId'] }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'name'>
      ) }
    )>, choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'choiceId' | 'name'>
    )> }
  )> }
);

export type AddMenuGetOrderableTimesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMenuGetOrderableTimesQuery = (
  { __typename?: 'query_root' }
  & { orderableTime: Array<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
  )> }
);

export type AddMenuGetCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMenuGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'companyId' | 'defaultMenuTaxMethod' | 'defaultCostTaxMethod'>
  )> }
);

export type AddMenuInsertMenuMutationVariables = Types.Exact<{
  menu: Types.CreateMenuInput;
}>;


export type AddMenuInsertMenuMutation = (
  { __typename?: 'mutation_root' }
  & { createMenu: (
    { __typename?: 'CreateMenuOutput' }
    & Pick<Types.CreateMenuOutput, 'menuId'>
  ) }
);


export const AddMenuGetCategoriesAndMenusDocument = gql`
    query AddMenuGetCategoriesAndMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
    categoryMenus {
      categoryId: _categoryId
      priority
    }
  }
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    menuId
    description
    featuredLabelText
    imageUrl
    name
    orderMaxNum
    orderMaxNumPerTableUser
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
    isTakeOut
    unitMaxOrderNumForNumPeople
    unitMaxOrderNumPerCustomer
    openPrice
    orderableTimeId
    shouldMergeSlipOptions
    costPrice
    costTaxRate
    menuType
    price
    categoryMenus(
      where: {category: {_not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      priority
      displayType
      category {
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
    ) {
      optionId
      _optionId
      option {
        id
      }
    }
    translations {
      columnName
      locale
      value
    }
  }
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {optionId: asc}
  ) {
    optionId
    name
    menuOptions(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: {_menuId: asc}
    ) {
      menuId: _menuId
      menu {
        name
      }
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      name
    }
  }
}
    `;

/**
 * __useAddMenuGetCategoriesAndMenusQuery__
 *
 * To run a query within a React component, call `useAddMenuGetCategoriesAndMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMenuGetCategoriesAndMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMenuGetCategoriesAndMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMenuGetCategoriesAndMenusQuery(baseOptions: Apollo.QueryHookOptions<AddMenuGetCategoriesAndMenusQuery, AddMenuGetCategoriesAndMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMenuGetCategoriesAndMenusQuery, AddMenuGetCategoriesAndMenusQueryVariables>(AddMenuGetCategoriesAndMenusDocument, options);
      }
export function useAddMenuGetCategoriesAndMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMenuGetCategoriesAndMenusQuery, AddMenuGetCategoriesAndMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMenuGetCategoriesAndMenusQuery, AddMenuGetCategoriesAndMenusQueryVariables>(AddMenuGetCategoriesAndMenusDocument, options);
        }
export type AddMenuGetCategoriesAndMenusQueryHookResult = ReturnType<typeof useAddMenuGetCategoriesAndMenusQuery>;
export type AddMenuGetCategoriesAndMenusLazyQueryHookResult = ReturnType<typeof useAddMenuGetCategoriesAndMenusLazyQuery>;
export type AddMenuGetCategoriesAndMenusQueryResult = Apollo.QueryResult<AddMenuGetCategoriesAndMenusQuery, AddMenuGetCategoriesAndMenusQueryVariables>;
export const AddMenuGetOrderableTimesDocument = gql`
    query AddMenuGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useAddMenuGetOrderableTimesQuery__
 *
 * To run a query within a React component, call `useAddMenuGetOrderableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMenuGetOrderableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMenuGetOrderableTimesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMenuGetOrderableTimesQuery(baseOptions: Apollo.QueryHookOptions<AddMenuGetOrderableTimesQuery, AddMenuGetOrderableTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMenuGetOrderableTimesQuery, AddMenuGetOrderableTimesQueryVariables>(AddMenuGetOrderableTimesDocument, options);
      }
export function useAddMenuGetOrderableTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMenuGetOrderableTimesQuery, AddMenuGetOrderableTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMenuGetOrderableTimesQuery, AddMenuGetOrderableTimesQueryVariables>(AddMenuGetOrderableTimesDocument, options);
        }
export type AddMenuGetOrderableTimesQueryHookResult = ReturnType<typeof useAddMenuGetOrderableTimesQuery>;
export type AddMenuGetOrderableTimesLazyQueryHookResult = ReturnType<typeof useAddMenuGetOrderableTimesLazyQuery>;
export type AddMenuGetOrderableTimesQueryResult = Apollo.QueryResult<AddMenuGetOrderableTimesQuery, AddMenuGetOrderableTimesQueryVariables>;
export const AddMenuGetCompanyDocument = gql`
    query AddMenuGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    companyId
    defaultMenuTaxMethod
    defaultCostTaxMethod
  }
}
    `;

/**
 * __useAddMenuGetCompanyQuery__
 *
 * To run a query within a React component, call `useAddMenuGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMenuGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMenuGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMenuGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<AddMenuGetCompanyQuery, AddMenuGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMenuGetCompanyQuery, AddMenuGetCompanyQueryVariables>(AddMenuGetCompanyDocument, options);
      }
export function useAddMenuGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMenuGetCompanyQuery, AddMenuGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMenuGetCompanyQuery, AddMenuGetCompanyQueryVariables>(AddMenuGetCompanyDocument, options);
        }
export type AddMenuGetCompanyQueryHookResult = ReturnType<typeof useAddMenuGetCompanyQuery>;
export type AddMenuGetCompanyLazyQueryHookResult = ReturnType<typeof useAddMenuGetCompanyLazyQuery>;
export type AddMenuGetCompanyQueryResult = Apollo.QueryResult<AddMenuGetCompanyQuery, AddMenuGetCompanyQueryVariables>;
export const AddMenuInsertMenuDocument = gql`
    mutation AddMenuInsertMenu($menu: CreateMenuInput!) {
  createMenu(input: $menu) {
    menuId
  }
}
    `;
export type AddMenuInsertMenuMutationFn = Apollo.MutationFunction<AddMenuInsertMenuMutation, AddMenuInsertMenuMutationVariables>;

/**
 * __useAddMenuInsertMenuMutation__
 *
 * To run a mutation, you first call `useAddMenuInsertMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuInsertMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuInsertMenuMutation, { data, loading, error }] = useAddMenuInsertMenuMutation({
 *   variables: {
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useAddMenuInsertMenuMutation(baseOptions?: Apollo.MutationHookOptions<AddMenuInsertMenuMutation, AddMenuInsertMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMenuInsertMenuMutation, AddMenuInsertMenuMutationVariables>(AddMenuInsertMenuDocument, options);
      }
export type AddMenuInsertMenuMutationHookResult = ReturnType<typeof useAddMenuInsertMenuMutation>;
export type AddMenuInsertMenuMutationResult = Apollo.MutationResult<AddMenuInsertMenuMutation>;
export type AddMenuInsertMenuMutationOptions = Apollo.BaseMutationOptions<AddMenuInsertMenuMutation, AddMenuInsertMenuMutationVariables>;