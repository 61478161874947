import { getTecAggregationIntegrationTypeName } from "models/tecAggregationIntegrationType";

import { Maybe, PikaichiConfig, TecAggregationConfig, WinboardConfig } from "types/graphql";

type shops = {
  pikaichiConfig?: Maybe<Pick<PikaichiConfig, "pikaichiConfigId" | "enabled">>;
  tecAggregationConfigs?: Maybe<Pick<TecAggregationConfig, "id" | "enabled" | "integrationType">>[];
  winboardConfig?: Maybe<Pick<WinboardConfig, "winboardConfigId" | "enabled">>;
}[];

export const hasEnabledWinboardConfig = (shops: shops) =>
  Boolean(shops.some((shop) => shop.winboardConfig?.enabled ?? false));

export const hasEnabledPikaichiConfig = (shops: shops) =>
  Boolean(shops.some((shop) => shop.pikaichiConfig?.enabled ?? false));

export const hasEnabledTecAggregationConfig = (shops: shops) =>
  Boolean(
    shops.some(
      ({ tecAggregationConfigs }) =>
        tecAggregationConfigs?.some((config) => config?.enabled) ?? false,
    ),
  );

export const concatTecAggregationIntegrationTypeName = (shops: shops) =>
  Array.from(
    new Set(
      shops
        .flatMap(({ tecAggregationConfigs }) =>
          tecAggregationConfigs
            ?.filter((config) => config?.enabled)
            .map((config) =>
              getTecAggregationIntegrationTypeName(config?.integrationType ?? "").slice(0, -2),
            ),
        )
        .filter(Boolean),
    ),
  ).join("/") + "連携";
