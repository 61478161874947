import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Alert, Badge, Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { Dealers } from "components/Dealers";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { Menu as MenuType, Shop } from "types/graphql";

import {
  concatTecAggregationIntegrationTypeName,
  hasEnabledPikaichiConfig,
  hasEnabledTecAggregationConfig,
  hasEnabledWinboardConfig,
} from "../biToolConfig";

import { useMenuHeaderGetBiMenusQuery } from "./queries";

type Props = {
  menu:
    | (Pick<MenuType, "name" | "menuId" | "totalDealingShopCount"> & {
        shopMenus: {
          shop: Pick<Shop, "shopId" | "name">;
        }[];
      })
    | null;
  onBack: () => void;
  shouldShowNetworkErrorAlert?: boolean;
  shouldShowManagingShopErrorAlert?: boolean;
};

export const MenuHeader = memo<Props>(
  ({ menu, onBack, shouldShowNetworkErrorAlert, shouldShowManagingShopErrorAlert }) => {
    const { pathname } = useLocation();
    const { canAccess } = useCanAccess();
    const [company] = useCompany();
    const shops = company?.shops ?? [];

    const selectedKey = pathname.split("/")[3];
    const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

    const dealers = menu?.shopMenus.flatMap(({ shop }) => shop);

    const isPikaichiEnabled = canAccess("editMenuPikaichiMenu") && hasEnabledPikaichiConfig(shops);

    const isTecAggregationEnabled =
      canAccess("editMenuTecAggregationMenu") && hasEnabledTecAggregationConfig(shops);

    const isWinboardEnabled = canAccess("editMenuWinboardMenu") && hasEnabledWinboardConfig(shops);

    const { data: getMenuBiData } = useMenuHeaderGetBiMenusQuery(
      menu?.menuId
        ? {
            variables: {
              menuId: menu.menuId,
              isPikaichiEnabled,
              isTecAggregationEnabled,
              isWinboardEnabled,
            },
          }
        : { skip: true },
    );

    const pikaichiMenu = getMenuBiData?.pikaichiMenuMenu?.[0];
    const tecAggregationMenu = getMenuBiData?.tecAggregationMenu?.[0];
    const winboardMenu = getMenuBiData?.winboardMenu?.[0];

    const tecAggregationIntegrationTypeName = concatTecAggregationIntegrationTypeName(shops);

    return (
      <>
        <PageHeader
          onBack={onBack}
          title={menu?.name ?? ""}
          footer={
            <Dealers
              shops={dealers ?? null}
              hiddenShopCount={(menu?.totalDealingShopCount ?? 0) - (menu?.shopMenus.length ?? 0)}
            />
          }
        />
        {shouldShowNetworkErrorAlert ? (
          <>
            <Alert
              message="通信に失敗しました"
              type="error"
              description="ネットワーク環境を確認してください"
            />
            <Spacer size={16} />
          </>
        ) : shouldShowManagingShopErrorAlert ? (
          <>
            <Alert
              showIcon
              type="warning"
              message="このメニューは編集できません。"
              description="このメニューは他の店舗でも取り扱われているため、編集することができません。編集したい場合は法人管理者または業態管理者に依頼してください。"
            />
            <Spacer size={16} />
          </>
        ) : null}
        {menu && (
          <Menu selectedKeys={selectedKeys} mode="horizontal">
            <Menu.Item key="edit">
              <Link to={`/menu/${menu.menuId}/edit`} replace>
                基本情報
              </Link>
            </Menu.Item>
            <Menu.Item key="option">
              <Link to={`/menu/${menu.menuId}/option`} replace>
                オプション
              </Link>
            </Menu.Item>
            <Menu.Item key="dealer">
              <Link to={`/menu/${menu.menuId}/dealer/edit`} replace>
                取扱設定
              </Link>
            </Menu.Item>
            <Menu.Item key="translation">
              <Link to={`/menu/${menu.menuId}/translation/edit`} replace>
                多言語設定
              </Link>
            </Menu.Item>
            {isPikaichiEnabled && (
              <Menu.Item key="pikaichi">
                <Link to={`/menu/${menu.menuId}/pikaichi/edit`} replace>
                  ぴかいちナビ連携
                </Link>
                {!pikaichiMenu && (
                  <>
                    <Spacer width={5} inline />
                    <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                  </>
                )}
              </Menu.Item>
            )}
            {isTecAggregationEnabled && (
              <Menu.Item key="tec">
                <Link to={`/menu/${menu.menuId}/tec/edit`} replace>
                  {tecAggregationIntegrationTypeName ? tecAggregationIntegrationTypeName : "-"}
                </Link>
                {!tecAggregationMenu && (
                  <>
                    <Spacer width={5} inline />
                    <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                  </>
                )}
              </Menu.Item>
            )}
            {isWinboardEnabled && (
              <Menu.Item key="winboard">
                <Link to={`/menu/${menu.menuId}/winboard/edit`} replace>
                  ウィンボード連携
                  {!winboardMenu && (
                    <>
                      <Spacer width={5} inline />
                      <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                    </>
                  )}
                </Link>
              </Menu.Item>
            )}
          </Menu>
        )}
      </>
    );
  },
);
