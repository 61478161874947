import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenuMasterCsvGetCompanyShops = gql`
    query MenuMasterCsvGetCompanyShops($companyId: uuid) {
  shop(where: {companyId: {_eq: $companyId}}) {
    shopId
  }
}
    `;
export type MenuMasterCsvGetCompanyShopsQueryVariables = Types.Exact<{
  companyId?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type MenuMasterCsvGetCompanyShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
  )> }
);


export const MenuMasterCsvGetCompanyShopsDocument = gql`
    query MenuMasterCsvGetCompanyShops($companyId: uuid) {
  shop(where: {companyId: {_eq: $companyId}}) {
    shopId
  }
}
    `;

/**
 * __useMenuMasterCsvGetCompanyShopsQuery__
 *
 * To run a query within a React component, call `useMenuMasterCsvGetCompanyShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuMasterCsvGetCompanyShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuMasterCsvGetCompanyShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenuMasterCsvGetCompanyShopsQuery(baseOptions?: Apollo.QueryHookOptions<MenuMasterCsvGetCompanyShopsQuery, MenuMasterCsvGetCompanyShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuMasterCsvGetCompanyShopsQuery, MenuMasterCsvGetCompanyShopsQueryVariables>(MenuMasterCsvGetCompanyShopsDocument, options);
      }
export function useMenuMasterCsvGetCompanyShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuMasterCsvGetCompanyShopsQuery, MenuMasterCsvGetCompanyShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuMasterCsvGetCompanyShopsQuery, MenuMasterCsvGetCompanyShopsQueryVariables>(MenuMasterCsvGetCompanyShopsDocument, options);
        }
export type MenuMasterCsvGetCompanyShopsQueryHookResult = ReturnType<typeof useMenuMasterCsvGetCompanyShopsQuery>;
export type MenuMasterCsvGetCompanyShopsLazyQueryHookResult = ReturnType<typeof useMenuMasterCsvGetCompanyShopsLazyQuery>;
export type MenuMasterCsvGetCompanyShopsQueryResult = Apollo.QueryResult<MenuMasterCsvGetCompanyShopsQuery, MenuMasterCsvGetCompanyShopsQueryVariables>;