import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationUploadGetShops = gql`
    query TecAggregationUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    tecAggregationConfigs {
      id
      enabled
      integrationType
    }
  }
}
    `;
export type TecAggregationUploadGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationUploadGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shops: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { tecAggregationConfigs: Array<(
      { __typename?: 'tecAggregationConfig' }
      & Pick<Types.TecAggregationConfig, 'id' | 'enabled' | 'integrationType'>
    )> }
  )> }
);


export const TecAggregationUploadGetShopsDocument = gql`
    query TecAggregationUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    tecAggregationConfigs {
      id
      enabled
      integrationType
    }
  }
}
    `;

/**
 * __useTecAggregationUploadGetShopsQuery__
 *
 * To run a query within a React component, call `useTecAggregationUploadGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationUploadGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationUploadGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationUploadGetShopsQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationUploadGetShopsQuery, TecAggregationUploadGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationUploadGetShopsQuery, TecAggregationUploadGetShopsQueryVariables>(TecAggregationUploadGetShopsDocument, options);
      }
export function useTecAggregationUploadGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationUploadGetShopsQuery, TecAggregationUploadGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationUploadGetShopsQuery, TecAggregationUploadGetShopsQueryVariables>(TecAggregationUploadGetShopsDocument, options);
        }
export type TecAggregationUploadGetShopsQueryHookResult = ReturnType<typeof useTecAggregationUploadGetShopsQuery>;
export type TecAggregationUploadGetShopsLazyQueryHookResult = ReturnType<typeof useTecAggregationUploadGetShopsLazyQuery>;
export type TecAggregationUploadGetShopsQueryResult = Apollo.QueryResult<TecAggregationUploadGetShopsQuery, TecAggregationUploadGetShopsQueryVariables>;