import React, { memo } from "react";
import { Switch } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

import { useEditTecAggregationConfigFormPermission } from "../useEditTecAggregationConfigFormPermission";

type Props = Omit<FormItemProps, "children">;

export const EnabledField = memo<Props>((props) => {
  const { canToggleStatus } = useEditTecAggregationConfigFormPermission();
  return (
    <FormItem
      label="連携"
      name="enabled"
      rules={[{ required: canToggleStatus }]}
      required
      valuePropName="checked"
      {...props}
    >
      <Switch disabled={!canToggleStatus} />
    </FormItem>
  );
});
