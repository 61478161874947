import useAsyncFn from "react-use/esm/useAsyncFn";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

import { message } from "components/antd/message";
import { useDinii } from "hooks/useDinii";
import { TecAggregationIntegrationTypeEnum } from "types/graphql";

export const useTecAggregationGetZipToUpload = () => {
  const [dinii, getContext] = useDinii();

  const [{ loading }, submit] = useAsyncFn(
    async ({
      date,
      integrationType,
    }: {
      date: Date;
      integrationType?: TecAggregationIntegrationTypeEnum;
    }) => {
      if (!integrationType) return;

      const context = await getContext();

      if (!context) {
        return;
      }

      try {
        const { data } = await dinii.tec.getZipToUpload(context, date, integrationType);
        saveAs(data, `${dayjs(date).format("YYYY-MM-DD")}.zip`);
      } catch (err: any) {
        try {
          // responseType が blob となっている
          const resString = await err.response.data.text();
          const res = JSON.parse(resString);
          message.error(res.title);
        } catch {
          message.error("ダウンロードに失敗しました");
        }
      }
    },
    [dinii.tec, getContext],
  );

  return {
    loading,
    submit,
  };
};
