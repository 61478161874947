import React, { memo } from "react";
import { Input } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

import { useEditTecAggregationConfigFormPermission } from "../useEditTecAggregationConfigFormPermission";

type Props = Omit<FormItemProps, "children">;

export const FtpPasswordField = memo<Props>((props) => {
  const { canEditForm } = useEditTecAggregationConfigFormPermission();
  return (
    <FormItem
      label="FTPパスワード"
      name="ftpPassword"
      rules={[{ required: canEditForm }]}
      required
      {...props}
    >
      <Input type="password" disabled={!canEditForm} />
    </FormItem>
  );
});
