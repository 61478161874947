import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DashboardLayoutGetCompany = gql`
    query DashboardLayoutGetCompany {
  company(where: {archivedAt: {_is_null: true}}) {
    id
    corporationId
    companyId
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      name
      shopId
      useKd
      useKiosk
      pikaichiConfig {
        pikaichiConfigId
        enabled
      }
      winboardConfig {
        winboardConfigId
        enabled
      }
      tecAggregationConfigs {
        id
        enabled
        integrationType
      }
      foodingJournalConfig {
        id
      }
    }
  }
}
    `;
export const DashboardLayoutGetDashboardAccount = gql`
    query DashboardLayoutGetDashboardAccount($email: String!) {
  dashboardAccount(where: {email: {_eq: $email}}) {
    userName
    id
  }
}
    `;
export type DashboardLayoutGetCompanyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardLayoutGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'corporationId' | 'companyId' | 'name'>
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'name' | 'shopId' | 'useKd' | 'useKiosk'>
      & { pikaichiConfig?: Types.Maybe<(
        { __typename?: 'pikaichiConfig' }
        & Pick<Types.PikaichiConfig, 'pikaichiConfigId' | 'enabled'>
      )>, winboardConfig?: Types.Maybe<(
        { __typename?: 'winboardConfig' }
        & Pick<Types.WinboardConfig, 'winboardConfigId' | 'enabled'>
      )>, tecAggregationConfigs: Array<(
        { __typename?: 'tecAggregationConfig' }
        & Pick<Types.TecAggregationConfig, 'id' | 'enabled' | 'integrationType'>
      )>, foodingJournalConfig?: Types.Maybe<(
        { __typename?: 'foodingJournalConfig' }
        & Pick<Types.FoodingJournalConfig, 'id'>
      )> }
    )> }
  )> }
);

export type DashboardLayoutGetDashboardAccountQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type DashboardLayoutGetDashboardAccountQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccount: Array<(
    { __typename?: 'dashboardAccount' }
    & Pick<Types.DashboardAccount, 'userName' | 'id'>
  )> }
);


export const DashboardLayoutGetCompanyDocument = gql`
    query DashboardLayoutGetCompany {
  company(where: {archivedAt: {_is_null: true}}) {
    id
    corporationId
    companyId
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      name
      shopId
      useKd
      useKiosk
      pikaichiConfig {
        pikaichiConfigId
        enabled
      }
      winboardConfig {
        winboardConfigId
        enabled
      }
      tecAggregationConfigs {
        id
        enabled
        integrationType
      }
      foodingJournalConfig {
        id
      }
    }
  }
}
    `;

/**
 * __useDashboardLayoutGetCompanyQuery__
 *
 * To run a query within a React component, call `useDashboardLayoutGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardLayoutGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardLayoutGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardLayoutGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<DashboardLayoutGetCompanyQuery, DashboardLayoutGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardLayoutGetCompanyQuery, DashboardLayoutGetCompanyQueryVariables>(DashboardLayoutGetCompanyDocument, options);
      }
export function useDashboardLayoutGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardLayoutGetCompanyQuery, DashboardLayoutGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardLayoutGetCompanyQuery, DashboardLayoutGetCompanyQueryVariables>(DashboardLayoutGetCompanyDocument, options);
        }
export type DashboardLayoutGetCompanyQueryHookResult = ReturnType<typeof useDashboardLayoutGetCompanyQuery>;
export type DashboardLayoutGetCompanyLazyQueryHookResult = ReturnType<typeof useDashboardLayoutGetCompanyLazyQuery>;
export type DashboardLayoutGetCompanyQueryResult = Apollo.QueryResult<DashboardLayoutGetCompanyQuery, DashboardLayoutGetCompanyQueryVariables>;
export const DashboardLayoutGetDashboardAccountDocument = gql`
    query DashboardLayoutGetDashboardAccount($email: String!) {
  dashboardAccount(where: {email: {_eq: $email}}) {
    userName
    id
  }
}
    `;

/**
 * __useDashboardLayoutGetDashboardAccountQuery__
 *
 * To run a query within a React component, call `useDashboardLayoutGetDashboardAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardLayoutGetDashboardAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardLayoutGetDashboardAccountQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDashboardLayoutGetDashboardAccountQuery(baseOptions: Apollo.QueryHookOptions<DashboardLayoutGetDashboardAccountQuery, DashboardLayoutGetDashboardAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardLayoutGetDashboardAccountQuery, DashboardLayoutGetDashboardAccountQueryVariables>(DashboardLayoutGetDashboardAccountDocument, options);
      }
export function useDashboardLayoutGetDashboardAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardLayoutGetDashboardAccountQuery, DashboardLayoutGetDashboardAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardLayoutGetDashboardAccountQuery, DashboardLayoutGetDashboardAccountQueryVariables>(DashboardLayoutGetDashboardAccountDocument, options);
        }
export type DashboardLayoutGetDashboardAccountQueryHookResult = ReturnType<typeof useDashboardLayoutGetDashboardAccountQuery>;
export type DashboardLayoutGetDashboardAccountLazyQueryHookResult = ReturnType<typeof useDashboardLayoutGetDashboardAccountLazyQuery>;
export type DashboardLayoutGetDashboardAccountQueryResult = Apollo.QueryResult<DashboardLayoutGetDashboardAccountQuery, DashboardLayoutGetDashboardAccountQueryVariables>;